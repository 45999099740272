/* eslint-disable no-param-reassign */
import React from "react";

const ProgressBar = ({ progressBarRef, audioRef, timeProgress, duration }) => {
  const handleProgressChange = () => {
    audioRef.current.currentTime = progressBarRef.current.value;
  };

  const formatTime = (time) => {
    if (time && !Number.isNaN(time)) {
      const minutes = Math.floor(time / 60);
      const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const seconds = Math.floor(time % 60);
      const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      return `${formatMinutes}:${formatSeconds}`;
    }
    return "00:00";
  };

  return (
    <div className="flex flex-col justify-center w-full px-6 mx-auto text-white lg:px-12">
      <input
        type="range"
        ref={progressBarRef}
        defaultValue="0"
        onChange={handleProgressChange}
      />
      <div className="flex w-auto mx-0 mt-2 font-light flex-nowrap">
        <span className="ml-0 mr-auto text-base">
          {formatTime(timeProgress)}
        </span>
        <span className="ml-auto mr-0 text-base">{formatTime(duration)}</span>
      </div>
    </div>
  );
};

export default ProgressBar;

/* eslint-disable no-unused-vars */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import { Location } from "src/types";

const MarketplaceActions = () => {
  const [loading, _setLoading] = useState(false);
  const location = useLocation() as Location;
  const { message, tabid, pubkeyHash, id, uid, marketplaceAction } =
    location.state;
  // TODO: add analytics

  useEffect(() => {
    window.onbeforeunload = () => {
      chrome.runtime.sendMessage({
        call: "focus_last_active_tab",
      });
      chrome.runtime.sendMessage({
        call: "reload_script_and_tabs",
      });
    };
  }, []);

  return (
    <div className="flex justify-center w-11/12 mx-auto overflow-y-auto h-page 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
      <div className="flex flex-col justify-center w-full h-full mx-auto content-evenly">
        <h1 className="mt-12 mb-20 text-xl font-normal text-center text-black xs:text-2xl md:text-3xl dark:text-white">
          {marketplaceAction.title}
        </h1>
        <div className="flex flex-col justify-center w-full m-auto">
          <div className="flex flex-row w-full m-auto">
            <div className="flex flex-col justify-center w-full m-auto">
              <img
                src={
                  ![undefined, "undefined"].includes(uid)
                    ? `${process.env.REACT_APP_BUCKET_URI}/${uid}-profile`
                    : "/images/defaultAvatar.png"
                }
                className="order-2 w-12 h-12 m-auto rounded-full"
                alt="profile, or collection"
              />
              <div className="pt-1 pb-6 pl-2 text-base font-light text-center dark:text-gray-300">
                DIBA account
              </div>
            </div>
            <div className="mb-6 border-gray-500 border-dashed border-b-1 w-120" />
            <div className="flex flex-col justify-center w-full m-auto">
              <img
                src="/images/logo_128.png"
                className="order-2 w-12 h-12 m-auto rounded-full"
                alt="profile, or collection"
              />
              <div className="pt-1 pb-6 pl-2 text-base font-light text-center dark:text-gray-300">
                BitMask Wallet
              </div>
            </div>
          </div>
          <div className="pl-2 text-base font-light text-center border-gray-800 pb-9 mt-9 xs:text-lg pt-9 dark:text-gray-300 border-t-1">
            {marketplaceAction.description.includes("diba.io") ? (
              <p>
                {marketplaceAction.description.replace("diba.io", "")}{" "}
                <a
                  href="https://diba.io"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  diba.io
                </a>
                .
              </p>
            ) : (
              marketplaceAction.description
            )}
          </div>
        </div>
        <div className="w-10/12 m-auto mb-12 xs:w-9/12 grid grid-cols-2 gap-6 sm:mb-20">
          <RoundedButton
            className="text-base text-black border-2 border-black-500 dark:border-yellow-500 dark:text-yellow-400"
            onClick={() => {
              chrome.tabs.sendMessage(
                tabid,
                { authenticated: "no", answer: message, pubkeyHash, id },
                (response) => {
                  chrome.runtime
                    .sendMessage({
                      call: "focus_last_active_tab",
                    })
                    .then(() => window.close());
                }
              );
              chrome.runtime
                .sendMessage({
                  call: "focus_last_active_tab",
                })
                .then(() => window.close());
            }}
          >
            Cancel
          </RoundedButton>
          <RoundedButton
            className="text-base bg-yellow-500 text-newdarkmode-900"
            onClick={() => {
              chrome.tabs.sendMessage(
                tabid,
                { authenticated: "yes", answer: message, pubkeyHash, id },
                (response) => {
                  chrome.runtime
                    .sendMessage({
                      call: "focus_last_active_tab",
                    })
                    .then(() => window.close());
                }
              );
            }}
            loading={loading}
          >
            Authorize
          </RoundedButton>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceActions;

/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import { Location } from "src/types";
import { updatingAssets } from "src/aux/updateAssets";
import Mixpanel from "src/lib/Mixpanel";

const ImportDescription = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const [loading, setLoading] = useState(false);
  const { walletData, vault, asset: assetData, source } = location.state;

  const asset = {
    ticker: assetData.ticker,
    name: assetData.name,
    description: assetData.description,
    amount: assetData.balance,
    id: assetData.id,
  };

  ReactGA.pageview("Import Description");
  Mixpanel.track("Viewed Import Description", {
    Asset: asset,
    Source: source.view,
    Search: source?.param,
  });
  ReactGA.pageview("Import Asset Confirm");
  Mixpanel.track("Viewed Import Confirmation", {
    Asset: assetData,
    Source: source.view,
  });

  const handleSubmit = async () => {
    setLoading(true);
    const futureAssets = await updatingAssets(walletData, assetData);
    console.debug(`Value is set to ${futureAssets}`);
    ReactGA.event({
      category: "Import asset",
      action: "Import asset",
      label: assetData.id,
    });
    Mixpanel.track("Imported Asset", {
      Asset: assetData,
      Source: source.view,
    });
    navigate("/wallet", { state: { wallet: walletData.name, vault } });
  };

  return (
    <PageWrapper
      title="Import Confirmation"
      handlePageBack={() =>
        navigate("/importassets", { state: { walletData, vault } })
      }
      handleCancel={() =>
        navigate("/wallet", { state: { wallet: walletData.name, vault } })
      }
      handleSubmit={() => handleSubmit()}
      loading={loading}
      confirmation
    >
      <div className="text-black dark:text-white sm:bg-gray-300 sm:dark:bggradient sm:rounded-lg sm:shadow-lg sm:p-12">
        <div className="grid grid-cols-2">
          <p className="my-4 text-lg font-semibold text-left flex-grow-default">
            Asset Ticker:
          </p>
          <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
            {asset.ticker}
          </p>
        </div>
        <div className="grid grid-cols-2">
          <p className="my-4 text-lg font-semibold text-left flex-grow-default">
            Asset Name:
          </p>
          <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
            {asset.name}
          </p>
        </div>
        <div className="grid grid-cols-2">
          <p className="my-4 text-lg font-semibold text-left flex-grow-default">
            Amount:
          </p>
          <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
            {asset.amount.toLocaleString()}
          </p>
        </div>
        <div className="grid grid-cols-2">
          <p className="my-4 text-lg font-semibold text-left flex-grow-default">
            {asset.id ? "Asset ID" : "Genesis ID"}:
          </p>
          <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
            {asset.id}
          </p>
        </div>
        <div className="grid grid-cols-2">
          <p className="my-4 text-lg font-semibold text-left flex-grow-default">
            Description:
          </p>
          <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
            {asset.description}
          </p>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ImportDescription;

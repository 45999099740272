/* eslint-disable @typescript-eslint/no-explicit-any */
/* global chrome */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import * as backend from "src/Backend";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Mixpanel from "src/lib/Mixpanel";
import { Vault } from "src/types";
import lib, { isExtension } from "src/lib";

interface Props {
  walletBalance: string;
  vault: Vault;
  tokensWalletAddress: string;
  udasWalletAddress: string;
  setOpenFundAssetsModal: any;
}

const FundAssets = ({ ...props }) => {
  const {
    walletBalance,
    vault,
    tokensWalletAddress,
    udasWalletAddress,
    setOpenFundAssetsModal,
  } = props as Props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const navigate = useNavigate();
  Mixpanel.track("View Fund Vault Confirmation");

  return (
    <div className="flex flex-col w-full max-w-3xl m-auto sm:px-6 sm:pt-3">
      {Number(walletBalance) >= 4000 ? (
        <div>
          {error.message === "" ? (
            <>
              <h1 className="w-10/12 mx-auto text-xl font-normal text-center md:w-11/12 md:text-2xl dark:text-white font-integral">
                Funding Assets Wallet
              </h1>
              <p className="w-10/12 mx-auto my-3 text-base font-light text-gray-800 break-words md:w-11/12 sm:my-5 dark:text-gray-300">
                You are going to send 4000 sats to your assets wallet to fund it
                and start receiving and sending assets
              </p>
              <div className="w-8/12 mx-auto my-3 sm:w-5/12">
                <RoundedButton
                  className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm"
                  onClick={async () => {
                    try {
                      setLoading(true);
                      const fundVault = await backend.fund_vault(
                        vault.btcDescriptorXprv,
                        vault.btcChangeDescriptorXprv,
                        tokensWalletAddress,
                        udasWalletAddress,
                        BigInt(546),
                        BigInt(546),
                        3.0 // TODO: configurable fee rate
                      );
                      lib.storageSet({ funded: true }).then(() => {
                        Mixpanel.track("Funded Vault", {
                          Transaction: fundVault,
                        });
                        setOpenFundAssetsModal(false);
                        if (isExtension) {
                          chrome.runtime.sendMessage({
                            call: "reload_script_and_tabs",
                          });
                        }
                        navigate("/");
                      });
                    } catch (err: any) {
                      setLoading(false);
                      setError({
                        title: "An error occurred while funding asset wallet",
                        message: err?.message || err.toString(),
                      });
                    }
                  }}
                  loading={loading}
                >
                  Okay
                </RoundedButton>
                <RoundedButton
                  className="inline-flex justify-center w-full px-4 mt-3 text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm"
                  onClick={() => {
                    setOpenFundAssetsModal(false);
                  }}
                >
                  Cancel
                </RoundedButton>
              </div>
            </>
          ) : (
            <div className="w-8/12 mx-auto my-3 sm:w-5/12">
              <h1 className="w-10/12 mx-auto text-lg font-normal text-center md:text-2xl dark:text-white font-integral">
                {error.title}
              </h1>
              <p className="w-11/12 mx-auto my-3 text-base font-light text-gray-800 break-words dark:text-gray-300">
                {error.message}
              </p>
              <RoundedButton
                className="inline-flex justify-center w-full max-w-xs px-4 mt-3 text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm"
                onClick={() => {
                  setOpenFundAssetsModal(false);
                }}
              >
                Okay
              </RoundedButton>
            </div>
          )}
        </div>
      ) : (
        <>
          <h1 className="w-10/12 mx-auto text-lg font-normal text-center text-black md:w-11/12 md:text-xl dark:text-white font-integral">
            Wallet Balance is too Low to Fund Assets Vault
          </h1>
          <p className="w-10/12 mx-auto my-6 text-base font-light text-black break-words md:w-11/12 dark:text-gray-300">
            You need at least 4000 sats to create the required UTXOs. RGB
            transactions currently require on-chain sats to receive.
          </p>
          <RoundedButton
            className="inline-flex justify-center w-10/12 px-4 mt-1.5 mb-3 mx-auto text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm max-w-xs"
            onClick={() => {
              setOpenFundAssetsModal(false);
            }}
          >
            Okay
          </RoundedButton>
        </>
      )}
    </div>
  );
};

export default FundAssets;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import DropDown from "src/Components/FAQ/DropDown";
import SideOptions from "src/Components/FAQ/SideOptions";

const Faq = () => {
  const options = [
    {
      name: "General",
    },
    {
      name: "Getting Started",
    },
    {
      name: "Managing My Digital Assets",
    },
    {
      name: "Payments",
    },
    {
      name: "Security",
    },
    {
      name: "Troubleshooting",
    },
  ];
  const [selected, setSelected] = useState(options[0]);

  const faqGeneral = [
    {
      question: "What is BitMask? ",
      answer:
        "BitMask is a browser extension wallet created by the DIBA Team that supports stablecoins and Unique Digital Assets built on the Bitcoin blockchain using RGB Layer 3 smart contracts. ",
    },
    {
      question: "What is DIBA?",
      answer:
        "DIBA stands for Digital Bitcoin Art & Assets. It’s the first digital goods marketplace utilizing the RGB Smart Contract Protocol, which allows creation and transactions of Unique Digital Assets (Similar to “NFTs”) to be directly on Bitcoin without the use of any other tokens.",
    },
    {
      question: "Is BitMask a non-custodial wallet?",
      answer:
        "Yes, BitMask is a non-custodial wallet and gives the user full control over their private keys and funds.",
    },
    {
      question:
        "What’s the difference between custodial and non-custodial wallets?",
      answer: (
        <div>
          <p>
            A non-custodial wallet is a type of wallet that gives the user full
            control over their private keys and funds. In contrast to custodial
            wallets, where the private keys are managed by a third-party service
            provider, non-custodial wallets allow users to maintain sole
            ownership and control over their keys and digital assets.
          </p>
          <p className="mt-6">
            This means that users are responsible for securely storing and
            managing their own private keys, and they have the ability to access
            and transact with their funds without the need for approval or
            authorization from a third party. Non-custodial wallets are
            considered to be more secure and private than custodial wallets, but
            they also require users to have a greater level of knowledge and
            expertise in managing their own keys and digital assets.
          </p>
        </div>
      ),
    },
    {
      question: "Is BitMask an Open Source Wallet?",
      answer: (
        <span>
          Yes, since we believe in transparency, security, and flexibility. The
          source code for core wallet functionality is publicly available on{" "}
          <a
            href="https://github.com/diba-io/bitmask-core"
            className="underline cursor-pointer"
          >
            GitHub
          </a>
          , and it can be independently audited by security experts to ensure
          that it is secure and free of vulnerabilities.
        </span>
      ),
    },

    {
      question: "What is the Lightning Network?",
      answer: (
        <div>
          <p>
            The Lightning Network is a Layer 2 protocol designed to be layered
            on top of a blockchain-based cryptocurrency such as Bitcoin. It is
            intended to enable fast and cost-efficient transactions among
            participating nodes.
          </p>
          <span>
            Learn more at
            <a
              href="https://lightning.network"
              className="underline cursor-pointer"
            >
              Lightning.Network.
            </a>
          </span>
        </div>
      ),
    },
    {
      question: "What are RGB Smart Contracts?",
      answer:
        "RGB adds a generalized smart contract protocol without the need for a separate sidechain or fee token. On-chain fees are paid whenever changing token state anchored to specific Bitcoin outputs, such as transferring ownership of a token. For certain kinds of tokens, Lightning can be used to further reduce fees. The majority of RGB contract state is stored separately off-chain. DIBA is building tools to better manage user-encrypted RGB contract data.",
    },
  ];

  const faqGettingStarted = [
    {
      question: "How do I Install and Create a BitMask wallet?",
      answer: (
        <div>
          <span>
            Go to{" "}
            <a href="https://bitmask.app/" className="underline cursor-pointer">
              BitMask.app
            </a>
          </span>
          <p>
            and click the “Continue with Web Wallet” or “Download Chrome
            Extension” button, which will take you to the Google Chrome Store to
            download the extension.
          </p>
        </div>
      ),
    },
    {
      question: "What browsers support BitMask extension?",
      answer: (
        <div>
          <span>
            At the moment the BitMask browser extension is only available on the{" "}
            <a
              href="https://chrome.google.com/webstore/detail/bitmask/momakdpclmaphlamgjcndbgfckjfpemp"
              className="underline cursor-pointer"
            >
              Chrome Web Store
            </a>
            . Soon, BitMask will also support more browsers such as Firefox and
            Edge, and Safari.
          </span>
        </div>
      ),
    },
    {
      question:
        "Do I need to create a Lightning Network (LN) wallet separately?",
      answer:
        "No, you don’t need to create an LN Wallet separately. Wallet secrets like Lightning keys are encrypted and synced using a key generated by your wallet.",
    },
    {
      question: "Do I need Bitcoin to start using BitMask?",
      answer:
        "Yes, and only Bitcoin is used for fees, however, only testnet support is available at this time.",
    },
    {
      question:
        "How do I transfer my existing Bitcoin and assets into BitMask?",
      answer:
        "When mainnet support is available, an address and QR can be generated within either the extension or web wallet to send Bitcoin to your wallet.",
    },
    {
      question: "How do I receive and send Bitcoin using my wallet?",
      answer: "BLANK",
    },
    {
      question: "How do I get funds ( Bitcoin) into BitMask?",
      answer: "BLANK",
    },
    {
      question:
        "Can I transfer my Assets/Tokens on Stacks, Counterparty, Rootstock, Liquid, OmniLayer, etc., into BitMask?",
      answer:
        "Support for alternate Bitcoin-native token implementations using federated token bridging is planned, but not yet supported.",
    },
    {
      question: "What are the supported platforms and devices for the wallet?",
      answer: "BLANK",
    },
    {
      question: "Can I use BitMask to manage multiple cryptocurrencies?",
      answer:
        "Not really, the only blockchains with support planned are mainnet, testnet, signet, and regtest. At some point a token bridging solution is planned to help bring tokens from other chains onto RGB.",
    },
    {
      question: "How do I backup and restore my wallet?",
      answer:
        "Keep your mnemonic in a safe place and make sure it’s never shared with anyone else.",
    },
    {
      question:
        "How do I recover my wallet if I lose my password or mnemonic seed phrase?",
      answer:
        "If you lose your device password or PIN code, log out of the extension and import your mnemonic. If you lose your mnemonic, or your mnemonic encryption passphrase, the assets kept within the wallet are unrecoverable, since this is used to generate all keys in use by the wallet.",
    },
  ];

  const faqManagingMyDigitalAssets = [
    {
      question: "Which tokens does BitMask support?",
      answer:
        "BTC and any RGB-issued tokens (examples ?). You need to manually import tokens by RGB genesis or by RGB ID to see them within BitMask.",
    },
    {
      question: "Does BitMask support NFTs?",
      answer:
        "Yes, but we are calling them UDAs or Unique Digital Assets. Currently BitMask supports only UDAs issued on RGB20 smart contracts protocol and soon will support UDAs issued on RGB21 once it is released. In the future BitMask will support all BTC issued UDAs/NFTs.",
    },
  ];
  const faqPayments = [
    {
      question: "What is my public address?",
      answer: "BLANK",
    },
    {
      question: "What is the difference between private and public address?",
      answer: "BLANK",
    },
    {
      question: "What is my Lightning Network (LN) address?",
      answer: "BLANK",
    },
    {
      question: "How to deposit/receive/send?",
      answer: "BLANK",
    },
  ];
  const faqSecurity = [
    {
      question: "How do I keep my wallet safe?",
      answer: "BLANK",
    },
    {
      question: "How do I import my wallet into BitMask?",
      answer: "BLANK",
    },
    {
      question: "How do I know if a Bitcoin wallet extension is secure?",
      answer: "BLANK",
    },
    {
      question: "Can a Bitcoin wallet extension be hacked?",
      answer: "BLANK",
    },
    {
      question: "Lost my password: How do I restore my wallet?",
      answer: "BLANK",
    },
    {
      question: "Lost my secret recovery phrase: Can I restore my wallet?",
      answer: "BLANK",
    },
  ];

  const faqTroubleshooting = [
    {
      question: "How do I update my wallet or access the latest version?",
      answer: "BLANK",
    },
    {
      question: "Why hasn’t my balance updated?",
      answer: "BLANK",
    },
    {
      question: "Why don't I see my UDA’s?",
      answer: "BLANK",
    },
    {
      question: "I found a bug, what should I do?",
      answer: "BLANK",
    },
    {
      question: "I can’t import a token by RGB ID, what should I do?",
      answer: "BLANK",
    },
  ];

  return (
    <div className="h-full overflow-y-auto">
      <div className="flex flex-col w-11/12 min-h-screen m-auto">
        <h1 className="m-auto mt-3 mb-6 text-2xl font-bold text-white sm:text-4xl font-integral">
          FAQ
        </h1>
        <div className="flex flex-col w-full mx-auto lg:flex-row gap-6 md:gap-9 xl:gap-20">
          <div>
            <SideOptions
              options={options}
              value={selected}
              setValue={setSelected}
            />
          </div>
          <div className="flex-grow-default">
            {selected.name === "General" && <DropDown faqs={faqGeneral} />}
            {selected.name === "Getting Started" && (
              <DropDown faqs={faqGettingStarted} />
            )}
            {selected.name === "Managing My Digital Assets" && (
              <DropDown faqs={faqManagingMyDigitalAssets} />
            )}
            {selected.name === "Payments" && <DropDown faqs={faqPayments} />}
            {selected.name === "Security" && <DropDown faqs={faqSecurity} />}
            {selected.name === "Troubleshooting" && (
              <DropDown faqs={faqTroubleshooting} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga";

import * as backend from "src/Backend";
import FullWidthTextField from "src/Components/Inputs/FullWidthTextField";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import ErrorModal from "src/Components/Modals/Error";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Copy from "src/Components/Icons/Copy";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import lib, { isExtension } from "src/lib";
import { Asset, Location } from "src/types";
import Mixpanel from "src/lib/Mixpanel";
import getBlinding from "src/lib/getBlinding";
import Loader from "src/Components/Loaders/Loader";
import { classNames } from "src/Hooks/util";

// TODO: create new events for mixpanel tracking plan
const CreateAsset = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [genesis, setGenesis] = useState("");
  const [asset, setAsset] = useState<{
    ticker: string;
    name: string;
    precision: number;
    supply: bigint;
    utxo: string;
  }>({
    ticker: "",
    name: "",
    precision: 0,
    supply: BigInt(0),
    utxo: "",
  });
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { walletData, vault } = location.state;
  const [blinding, setBlinding] = useState({
    utxo: "",
    conceal: "",
    blinding: "",
  });

  useEffect(() => {
    const setBlindingProperty = async () => {
      setBlinding(
        await getBlinding(walletData.name, vault.rgbAssetsDescriptorXpub, true)
      );
    };

    // call the function
    setBlindingProperty()
      // make sure to catch any error
      .catch(console.error);
  }, []);
  const handleSubmit = async () => {
    if (blinding.utxo !== "") {
      try {
        const res = await backend.create_asset(
          asset.ticker,
          asset.name,
          asset.precision,
          asset.supply,
          blinding.utxo
        );
        setGenesis(res.genesis);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
        setError({ title: "Error creating asset", message: err.toString() });
        setOpen(true);
      }
    }
  };

  if (!blinding.utxo) {
    return (
      <div
        className={classNames(
          isExtension ? "min-w-[400px]" : "min-w-80",
          "flex justify-center w-full h-full m-auto text-gray-900 dark:text-gray-300 bg-gray-100 dark:bggradientlanding"
        )}
      >
        <Loader className="w-20 h-20 m-auto" />
      </div>
    );
  }

  if (genesis) {
    return (
      <div className="flex justify-center w-full h-full mx-auto min-h-page 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
        <div className="flex flex-col justify-between h-full m-auto text-center min-h-page">
          <h1 className="mt-6 text-2xl font-normal text-center text-black xs:text-3xl lg:text-4xl flex-shrink-default dark:text-green-500">
            Asset Creation Success
          </h1>
          <img
            className="m-auto my-8 w-36 h-36 sm:w-64 sm:h-64 lg:my-12"
            src="/images/greenCheck.png"
            alt="green check mark"
          />
          <div className="text-gray-900 dark:text-gray-500">
            <div className="flex flex-col w-full mb-6">
              <h1 className="px-3 my-3 text-xl font-normal text-gray-900 truncate dark:text-gray-300 flex-shrink-default">
                Genesis:
              </h1>
              <button
                type="button"
                className="flex w-auto px-3 py-1 m-auto font-normal text-center text-gray-900 bg-gray-300 rounded-lg cursor-pointer hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-400 max-w-88 sm:max-w-152 dark:bg-newdarkmode-700 flex-nowrap"
                onClick={() => {
                  navigator.clipboard.writeText(genesis);
                  setOpen(true);
                }}
                title="Copy genesis"
              >
                <div className="flex w-full m-auto flex-nowrap">
                  <p className="w-[85%] my-auto text-base font-light truncate sm:w-full">
                    {genesis}
                  </p>
                  <Copy className="my-auto ml-1 w-7 h-7 sm:w-9 sm:h-9" />
                </div>
              </button>
            </div>
          </div>
          <div className="w-9/12 py-6 mx-auto grid grid-cols-2 gap-6 sm:pb-12 lg:pb-20">
            <RoundedButton
              className="text-base text-black border-2 border-black-500 dark:border-yellow-500 dark:text-yellow-400"
              onClick={() =>
                navigate("/wallet", {
                  state: { wallet: walletData.name, vault },
                })
              }
            >
              Okay
            </RoundedButton>
            <RoundedButton
              className="text-base bg-yellow-500 border-2 border-yellow-500 text-newdarkmode-900"
              onClick={() => {
                navigate("/importassets", {
                  state: { walletData, vault, genesis, blinding },
                });
              }}
            >
              Import
            </RoundedButton>
          </div>
        </div>
        <CopiedToClipboard open={open} setOpen={setOpen} />
      </div>
    );
  }

  return (
    <PageWrapper
      title="Create Asset"
      handlePageBack={() =>
        navigate("/wallet", { state: { wallet: walletData.name, vault } })
      }
      handleCancel={() =>
        navigate("/wallet", { state: { wallet: walletData.name, vault } })
      }
      handleSubmit={() => {
        if (asset.ticker.length <= 2) {
          setError({
            title: "Value Error",
            message: "Ticker must be at least 3 characters",
          });
          setOpen(true);
        } else {
          setLoading(true);
          handleSubmit();
        }
      }}
      loading={loading}
    >
      <div className="flex flex-col justify-center w-full h-auto">
        <FullWidthTextField
          label="Ticker"
          type="text"
          className="w-full p-2 mx-0 xs:p-3 min-w-11/12 xs:min-w-9/12 flex-grow-default"
          placeholder="Ticker"
          onChange={(e) =>
            setAsset({ ...asset, ticker: e.target.value.toUpperCase() })
          }
        />
        <FullWidthTextField
          label="Asset Name"
          type="text"
          className="w-full p-2 mx-0 xs:p-3 min-w-11/12 xs:min-w-9/12 flex-grow-default"
          placeholder="Name"
          onChange={(e) => setAsset({ ...asset, name: e.target.value })}
        />
        <FullWidthTextField
          label="Total Supply"
          type="number"
          className="w-full p-2 mx-0 xs:p-3 min-w-11/12 xs:min-w-9/12 flex-grow-default"
          placeholder="Supply"
          onChange={(e) => setAsset({ ...asset, supply: e.target.value })}
        />
        <FullWidthTextField
          label="Precision"
          type="number"
          className="w-full p-2 mx-0 xs:p-3 min-w-11/12 xs:min-w-9/12 flex-grow-default"
          placeholder="Precision"
          onChange={(e) => setAsset({ ...asset, precision: e.target.value })}
        />
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default CreateAsset;

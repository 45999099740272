/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import matter from "gray-matter";
import { useParams } from "react-router-dom";
import Loader from "src/Components/Loaders/Loader";
import { isExtension } from "src/lib";
import { classNames } from "src/Hooks/util";
import Footer from "src/Components/Layout/Footers/Footer";

const components = {
  img: ({ node, ...props }) => <img className="w-full lg:my-6" {...props} />,
  a: ({ node, ...props }) => (
    <a
      href={props.href}
      className="font-normal underline lg:font-light"
      {...props}
    />
  ),
  h1: ({ node, ...props }) => (
    <h1 className="text-2xl font-light font-integral" {...props} />
  ),
  h2: ({ node, ...props }) => (
    <h2 className="text-2xl font-light font-integral" {...props} />
  ),
};

const Blog = () => {
  const [blogPost, setBlogPost] = useState(null);
  const { title } = useParams();
  console.log(title);

  useEffect(() => {
    import(`src/markdown/${title}.md`).then((res) => {
      fetch(res.default)
        .then((response) => response.text())
        .then((response) => {
          console.log(matter(response));
          setBlogPost(matter(response));
        })
        .catch((err) => console.log(err));
    });
  }, []);

  if (!blogPost?.data?.title) {
    return (
      <div
        className={classNames(
          isExtension ? "min-w-[400px]" : "min-w-80",
          "flex justify-center w-full h-full m-auto text-gray-900 dark:text-gray-300 bg-gray-100 dark:bggradientlanding dark:bg-newdarkmode-700"
        )}
      >
        <Loader className="w-20 h-20 m-auto" />
      </div>
    );
  }

  return (
    <div className="h-auto">
      <div className="flex flex-col max-w-4xl min-h-screen mx-6 my-auto mb-6 md:mx-auto">
        <div className="flex flex-col w-full lg:flex-row">
          <div className="p-4 mx-2 text-white whitespace-pre-wrap flex-grow-default md:mx-6 lg:mx-20">
            <h1 className="text-2xl font-thin xs:text-3xl lg:text-4xl text-left font-integral leading-9 lg:leading-[3.5rem]">
              {blogPost.data.title}
            </h1>
            <div className="my-6 text-base font-light text-gray-200 whitespace-pre-wrap sm:my-12 xs:text-lg lg:text-xl md:font-light leading-8 md:leading-9">
              <ReactMarkdown linkTarget="_blank" components={components}>
                {blogPost.content}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;

/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-console */

import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import getBlinding from "src/lib/getBlinding";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import ReactGA from "react-ga";
import { TEST_IDS } from "tests/ids";
import * as backend from "src/Backend";
import { NetworkContext } from "src/Hooks/NetworkContext";
import { MainNavContext } from "src/Hooks/MainNavContext";
import Copy from "src/Components/Icons/Copy";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Tabs from "src/Components/Tabs/Tabs";
import ArrowUpCircle from "src/Components/Icons/ArrowUpCircle";
import ArrowDownCircle from "src/Components/Icons/ArrowDownCircle";
import ChevronLeft from "src/Components/Icons/ChevronLeft";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Modal from "src/Components/Modals/Modal";
import ReleaseNotes from "src/Components/Modals/Children/ReleaseNotes";
import Loader from "src/Components/Loaders/Loader";
import {
  getConversion,
  classNames,
  addBalance,
  getLargeNumber,
} from "src/Hooks/util";
import Mixpanel from "src/lib/Mixpanel";
import lib from "src/lib";
import FundAssets from "src/Components/Modals/Children/FundAssets";
import { Activity, Asset, Collection, Location, WalletData } from "src/types";
import { GetWalletData, ImportAsset, LnWalletData } from "src/types/core";
import { releaseNotes } from "src/constants";
import Lightning from "src/Components/Icons/Lightning";
import AssetFooter from "src/Components/Layout/Footers/AssetFooter";

const Wallet = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { network } = useContext(NetworkContext);
  const { setMainNav } = useContext(MainNavContext);
  const [tokensWalletFunded, setTokensWalletFunded] = useState(false);
  const [loadingVault, setLoadingVault] = useState(false);
  const [lnWalletData, setLnWalletData] = useState<LnWalletData>(
    localStorage.getItem("lnWalletData")
      ? JSON.parse(localStorage.getItem("lnWalletData") || "")
      : []
  );
  const [tokensWalletAddress, setTokensWalletAddress] = useState("");
  const [udasWalletAddress, setUdasWalletAddress] = useState("");
  const [openFundAssetsModal, setOpenFundAssetsModal] = useState(false);
  const [view, setView] = useState(0);
  const [openVersionPopup, setOpenVersionPopup] = useState(false);
  const [walletData, setWalletData] = useState<WalletData>(
    localStorage.getItem("walletData")
      ? JSON.parse(localStorage.getItem("walletData") || "")
      : []
  );
  const [conversion, setConversion] = useState(0);
  const [activities, setActivities] = useState<Activity[]>(
    localStorage.getItem("transactionHistory")
      ? JSON.parse(localStorage.getItem("transactionHistory") || "")
      : []
  );
  const [assets, setAssets] = useState<Asset[]>(
    localStorage.getItem("assets")
      ? JSON.parse(localStorage.getItem("assets") || "")
      : []
  );
  const [collections, setCollections] = useState<Collection[]>(
    localStorage.getItem("udas")
      ? JSON.parse(localStorage.getItem("udas") || "")
      : []
  );
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { wallet, vault, lnCredentials } = location.state;
  const { VERSION, CONTEXT = "" } = process.env;

  const getPage = (param) => {
    switch (param) {
      case 0:
        return "Activities";
      case 1:
        return "Assets";
      case 2:
        return "UDAs";
      default:
        return "Activities";
    }
  };
  ReactGA.pageview("Wallet");

  const getLightningWalletData = async () => {
    const lnAccount = await backend.ln_get_balance(lnCredentials.accessToken);
    const lnTxs = await backend.ln_get_txs(lnCredentials.accessToken);
    const lnActivitiesData = lnTxs.map((transaction) => {
      let date = Math.round(Date.now() / 1000);
      if (transaction.created_at !== null)
        date = Number(transaction.created_at) / 1000;
      const activity: Activity = {
        ...transaction,
        lightning: true,
        date,
        id: transaction.txid,
        assetType: "sats",
        amount:
          (Number(transaction.outbound_amount) - Number(transaction.fees)) *
          100_000_000,
        fee: Number(transaction.fees) * 100_000_000,
        action:
          transaction.outbound_account_id === lnAccount[0].account_id
            ? "sent"
            : "received",
        status: "confirmed",
      };
      return activity;
    });
    localStorage.setItem(
      "lnWalletData",
      JSON.stringify({ balance: lnAccount[0], transactions: lnTxs })
    );
    setLnWalletData({ balance: lnAccount[0], transactions: lnTxs });
    return lnActivitiesData;
  };

  const getWalletData = async () => {
    const result = await lib.storageGet<WalletData>(["wallet"]);
    setWalletData(result.wallet);
    const { btcDescriptorXprv, btcChangeDescriptorXprv } = vault;
    console.debug({ btcDescriptorXprv, btcChangeDescriptorXprv });
    try {
      const response: GetWalletData = await backend.get_wallet_data(
        btcDescriptorXprv,
        btcChangeDescriptorXprv
      );
      const data = { ...response, name: wallet };
      Mixpanel.register_once({
        "Wallet ID": vault.xpubkh,
      });
      Mixpanel.register({
        "Number of Lifetime Transactions": data.transactions.length,
        "Lifetime Transaction Volume": data.transactions
          .map((transaction) => {
            if (transaction.sent === 0) {
              return transaction.received;
            }
            return transaction.sent - transaction.received + transaction.fee;
          })
          .reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          ),
      });
      setWalletData(data);
      await lib.storageSet({ wallet: data });
      console.debug(`Value is set to ${JSON.stringify(data)}`);
      localStorage.setItem("walletData", JSON.stringify(data));
      const key = `${wallet}.transactions`;
      const lnActivities = await getLightningWalletData();
      const layerOneActivitiesData = data.transactions.map((transaction) => {
        let date = Math.round(Date.now() / 1000);
        if (transaction.confirmationTime !== null)
          date = transaction.confirmationTime.timestamp;
        const activity: Activity = {
          ...transaction,
          date,
          id: transaction.txid,
          assetType: "sats",
          amount:
            transaction.sent === 0
              ? transaction.received
              : transaction.sent - transaction.fee,
          fee: transaction.fee,
          action: transaction.sent === 0 ? "received" : "sent",
          status: transaction.confirmed ? "confirmed" : "pending",
        };
        return activity;
      });
      const sortedActivities = [...layerOneActivitiesData];
      if (lnActivities) {
        sortedActivities.push(...lnActivities);
      }
      sortedActivities.sort((a, b) => {
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;
        return 0;
      });
      setActivities(sortedActivities);
      await lib.storageSet({ [key]: sortedActivities });
      localStorage.setItem(
        "transactionHistory",
        JSON.stringify(sortedActivities)
      );
      console.debug(`Value is set to ${JSON.stringify(sortedActivities)}`);
    } catch (error) {
      console.error("error in Wallet get_wallet_data", error);
    }
  };

  const getNewAssets = async () => {
    const key = `${wallet}.assets`;
    const result = await lib.storageGet<Asset[]>([key]);

    if (result[key]) {
      const futureAssets = result[key];
      setAssets(futureAssets);
      try {
        const blinding = await getBlinding(
          walletData.name,
          vault.rgbAssetsDescriptorXpub,
          true
        );
        const newAssets: ImportAsset[] = await Promise.all(
          futureAssets.map((asset) =>
            backend.import_asset(asset.id, blinding.utxo)
          )
        );

        setAssets(newAssets);
        await lib.storageSet({ [key]: newAssets });
      } catch (err) {
        console.log("error in Wallet import_asset", err);
      }
    }
  };

  const setTokensWallet = async () => {
    setLoadingVault(true);
    const { rgbAssetsDescriptorXpub } = vault;
    console.debug({ rgbAssetsDescriptorXpub });
    try {
      const walletDataTokens: GetWalletData = await backend.get_wallet_data(
        rgbAssetsDescriptorXpub
      );
      setTokensWalletAddress(walletDataTokens.address);
      await lib.storageSet({ unspents: walletDataTokens.utxos });
      console.debug(`Value is set to ${walletDataTokens.utxos}`);
      if (walletDataTokens?.utxos?.length < 2) {
        setLoadingVault(false);
        setTokensWalletFunded(false);
        await lib.storageSet({ funded: false });
      } else {
        setLoadingVault(false);
        setTokensWalletFunded(true);
        await lib.storageSet({ funded: true });
      }
    } catch (err) {
      setLoadingVault(false);
      console.error("error in Wallet get_tokens_wallet_data", err);
    }
  };

  const setFutureCollections = async () => {
    const key = `${wallet}.collections`;
    const result = await lib.storageGet<Collection[]>([key]);

    if (result[key]) {
      const futureCollections = result[key];
      setCollections(futureCollections);
      localStorage.setItem("udas", JSON.stringify(futureCollections));
    }
  };

  const setUdasWallet = async () => {
    const { rgbUdasDescriptorXpub } = vault;
    console.debug({ rgbUdasDescriptorXpub });

    try {
      const walletDataNfts: GetWalletData = await backend.get_wallet_data(
        rgbUdasDescriptorXpub
      );
      setUdasWalletAddress(walletDataNfts.address);
      await lib.storageSet({ unspentsForUdas: walletDataNfts.utxos });
      console.debug(`Value is set to ${walletDataNfts.utxos}`);
    } catch (err) {
      console.error("error in Wallet get_nfts_wallet_data", err);
    }
  };

  const getBtcPrice = async () => {
    const rate = await getConversion();
    setConversion(rate);
  };

  useEffect(() => {
    Mixpanel.track("Viewed Wallet", { Page: getPage(view) });
  }, [view]);

  const lnAuth = async () => {
    await backend.ln_auth(lnCredentials.login, lnCredentials.password);
  };

  useEffect(() => {
    if (
      lnCredentials?.login?.length > 0 ||
      lnCredentials?.password?.length > 0
    ) {
      lnAuth();
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!location.state.lnCredentials) {
      navigate("/", { replace: true });
    } else if (
      !location.state ||
      !location.state.vault ||
      !location.state.wallet
    ) {
      navigate("/", { replace: true });
    } else if (urlParams.get("page")) {
      const params = window.location.href
        .split("?")[1]
        .split("#")[0]
        .split("&")
        .map((e) => e.split("=")[1]);
      const handleNavigation = (action) =>
        navigate("/marketplaceaction", {
          replace: true,
          state: {
            message: params[0],
            tabid: parseInt(params[1] as string, 10),
            pubkeyHash: params[2],
            id: params[3],
            uid: params[4],
            marketplaceAction: {
              title: action.title,
              description: action.description,
            },
          },
        });
      switch (urlParams.get("page")) {
        case "get_utxo":
          handleNavigation({
            title: "Authorize UDA creation",
            description: "Proceed to authorize UDA creation from diba.io",
          });
          break;
        case "get_vault":
          handleNavigation({
            title: "Request to connect",
            description:
              "You are about to connect your BitMask wallet to your DIBA account.",
          });
          break;
        case "pass_asset":
          handleNavigation({
            title: "Request to transfer UDA",
            description: "UDA will be moved from DIBA's wallet into your own.",
          });
          break;
        case "transfer":
          handleNavigation({
            title: "Transfer UDA",
            description: "Request to authorize UDA transfer from diba.io",
          });
          break;
        default:
          break;
      }
    } else if (Object.keys(vault).length) {
      const walletInfo = localStorage.getItem("walletData");
      const lnWalletInfo = localStorage.getItem("lnWalletData");
      const transactionHistory = localStorage.getItem("transactionHistory");
      const localAssets = localStorage.getItem("assets");
      const udas = localStorage.getItem("udas");
      if (walletInfo) {
        setWalletData(JSON.parse(walletInfo));
        if (JSON.parse(walletInfo).utxos.length) {
          setLoadingVault(false);
          setTokensWalletFunded(true);
        } else {
          setTokensWalletFunded(false);
        }
      }
      if (transactionHistory) setActivities(JSON.parse(transactionHistory));
      if (udas) setCollections(JSON.parse(udas));
      if (localAssets) setAssets(JSON.parse(localAssets));
      if (lnWalletInfo) setLnWalletData(JSON.parse(lnWalletInfo));
      getWalletData();
      getNewAssets();
      setFutureCollections();
      setTokensWallet();
      setUdasWallet();
      getBtcPrice();
      if (!localStorage.getItem(`newVersionInfoPopup${VERSION}${CONTEXT}`)) {
        localStorage.setItem(`newVersionInfoPopup${VERSION}${CONTEXT}`, "true");
        setOpenVersionPopup(true);
      }
    } else {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (setMainNav) setMainNav(true);
  });

  if (walletData?.balance && lnWalletData?.balance?.balance) {
    return (
      <div className="flex flex-col w-full mx-auto overflow-x-hidden text-gray-900 h-page dark:text-gray-300 dark:bg-newdarkmode-900 md:dark:bggradientlanding">
        <div className="flex w-full h-full overflow-y-auto scrollbar dark:darkscrollbar md:mt-3">
          <div
            className={classNames(
              view === 0
                ? "min-h-[calc(100% - 56px)]"
                : "min-h-[calc((var(--vh) * 100)]",
              "h-auto min-h-full flex flex-col w-full sm:px-6 max-w-4xl mx-auto"
            )}
          >
            <div ref={ref} className="flex flex-col w-full h-auto">
              <div className="flex w-full flex-nowrap">
                <div className="flex flex-col w-full pt-4 pb-2 md:flex-row md:flex-nowrap xs:p-6 md:bg-gradient-to-l md:rounded-t-2xl from-gray-300 dark:from-newdarkmode-900 to-transparent">
                  <button
                    type="button"
                    title="Copy wallet address"
                    onClick={() => {
                      navigator.clipboard.writeText(walletData?.address || "");
                      setOpenCopyModal(true);
                      Mixpanel.track("Copied Wallet Address", {
                        Source: "Home Page",
                      });
                    }}
                    className="rounded-lg shadow-sm px-2 py-0.5 sm:py-1 flex bg-gray-300 dark:bg-newdarkmode-800 dark:bg-opacity-75 font-thin cursor-pointer text-xs text-center max-w-11/12 xs:w-auto m-auto md:hidden flex-nowrap text-gray-900 hover:bg-gray-300 dark:text-gray-500 dark:hover:text-gray-400"
                  >
                    <p className="flex flex-1 w-auto mx-2 my-auto text-center truncate">
                      {walletData?.address}
                    </p>
                    <Copy className="flex flex-none w-6 h-6" />
                  </button>
                  <div className="flex flex-col order-2 w-11/12 mx-auto xs:w-full md:w-6/12 md:pr-6 md:ml-0 md:order-1">
                    <button
                      type="button"
                      title="Copy wallet address"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          walletData?.address || ""
                        );
                        setOpenCopyModal(true);
                        Mixpanel.track("Copied Wallet Address", {
                          Source: "Home Page",
                        });
                      }}
                      className="rounded-lg shadow-sm px-2 py-0.5 sm:py-1 hidden bg-gray-400 dark:bg-newdarkmode-800 font-thin cursor-pointer text-xs text-center w-full mt-auto md:flex flex-nowrap text-gray-900 hover:bg-gray-300 dark:text-gray-500 dark:hover:text-gray-400"
                    >
                      <p className="flex flex-1 w-auto mx-2 my-auto text-center truncate">
                        {walletData?.address}
                      </p>
                      <Copy className="flex flex-none w-6 h-6" />
                    </button>
                    <div className="w-full my-auto grid grid-cols-2 gap-4">
                      <RoundedButton
                        className="text-base text-black bg-yellow-500 dark:border-2 dark:border-yellow-500 lg:text-lg"
                        onClick={() => {
                          Mixpanel.track("Tapped Send Bitcoin", {
                            Source: "Main Wallet",
                          });
                          ReactGA.event({
                            category: "Wallet",
                            action: "send",
                          });
                          navigate("/send", {
                            state: {
                              locationHistory: [window.location.hash],
                              type: "",
                              walletData,
                              vault,
                              lnCredentials,
                              asset: {
                                allocations: [],
                                balance: addBalance(walletData?.balance),
                                dollarBalance: (
                                  addBalance(walletData?.balance) * conversion
                                ).toFixed(3),
                                id: "",
                                name: "Satoshis",
                                ticker: "Sats",
                              },
                            },
                          });
                        }}
                        lessMargin
                      >
                        Send
                      </RoundedButton>
                      <RoundedButton
                        className="text-base text-black bg-yellow-500 dark:border-2 dark:border-yellow-500 lg:text-lg"
                        onClick={() => {
                          Mixpanel.track("Tapped Receive Bitcoin", {
                            Source: "Main Wallet",
                          });
                          navigate("/receive", {
                            state: {
                              walletData,
                              vault,
                              lnCredentials,
                              locationHistory: [window.location.hash],
                              tokensWalletFunded,
                              loadingVault,
                              tokensWalletAddress,
                              udasWalletAddress,
                            },
                          });
                        }}
                        lessMargin
                      >
                        Receive
                      </RoundedButton>
                    </div>
                  </div>
                  <div className="order-1 w-11/12 mx-auto mt-4 mb-5 xs:w-full md:w-full grid grid-cols-2 md:order-2 md:my-0">
                    <div className="flex-col space-y-1.5 sm:space-y-3 justify-center md:mx-auto w-full col-span-2 md:col-span-1 md:ml-3 bg-gradient-to-l from-blue-500 to-transparent md:bg-none rounded-t-2xl pl-3">
                      <p className="hidden my-auto mt-1 mb-1 text-sm font-normal text-center text-black xs:mt-3 dark:text-green-500">
                        {walletData?.name}
                      </p>
                      <div className="flex flex-nowrap md:flex-col gap-9 md:gap-2">
                        <p className="my-auto text-sm font-normal text-left text-blue-600 lg:text-base lg:font-semibold">
                          Bitcoin (L1)
                        </p>
                        <h1 className="mr-6 text-lg font-normal text-right text-black md:text-xl md:text-left md:mr-0 md:font-semibold dark:text-newdarkmode-900 md:dark:text-gray-300 flex-grow-default">
                          {addBalance(walletData?.balance) <= 999_999
                            ? addBalance(walletData?.balance).toLocaleString()
                            : getLargeNumber(
                                addBalance(walletData?.balance)
                              )}{" "}
                          {network === "Bitcoin Testnet" ? "tSats" : "Sats"}
                        </h1>
                      </div>
                      <div className="flex flex-nowrap md:flex-col gap-9 md:gap-3">
                        <p className="mb-2 text-sm font-light text-left text-black md:my-0 md:flex-grow-default dark:text-gray-300">
                          {Number(
                            addBalance(walletData?.balance) / 100_000_000
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 8,
                          })}{" "}
                          {network === "Bitcoin Testnet" ? "tBTC" : "BTC"}
                        </p>
                        <p className="mb-2 ml-auto mr-6 text-sm font-light text-left text-black md:my-0 md:mx-0 md:flex-grow-default dark:text-newdarkmode-900 md:dark:text-gray-300">
                          {Number(
                            (addBalance(walletData?.balance) / 100_000_000) *
                              conversion
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 3,
                          })}{" "}
                          USD
                        </p>
                      </div>
                    </div>
                    <div className="flex-col space-y-1.5 sm:space-y-3 justify-center md:mx-auto w-full col-span-2 md:col-span-1 bg-gradient-to-l from-yellow-500 to-transparent md:bg-none rounded-b-2xl pl-3 mt-1.5 md:mt-0">
                      <p className="hidden mt-1 mb-1 text-sm font-normal text-center text-black xs:mt-3 dark:text-green-500">
                        {walletData?.name}
                      </p>
                      <div className="flex flex-nowrap md:flex-col gap-9 md:gap-2">
                        <p className="my-auto text-sm font-normal text-left text-yellow-600 lg:text-base lg:font-semibold">
                          Lightning (L2)
                        </p>
                        <h1 className="mr-6 text-lg font-normal text-right text-black md:text-xl md:text-left md:mr-0 md:font-semibold dark:text-newdarkmode-900 md:dark:text-gray-300 flex-grow-default">
                          {Number(lnWalletData.balance.balance) * 100_000_000 <=
                          999_999
                            ? (
                                Number(lnWalletData.balance.balance) *
                                100_000_000
                              ).toLocaleString()
                            : getLargeNumber(
                                Number(lnWalletData.balance.balance) *
                                  100_000_000
                              )}{" "}
                          {network === "Bitcoin Testnet" ? "tSats" : "Sats"}
                        </h1>
                      </div>
                      <div className="flex flex-nowrap md:flex-col gap-9 md:gap-3">
                        <p className="mb-2 text-sm font-light text-left text-black md:my-0 md:flex-grow-default dark:text-gray-300">
                          {Number(lnWalletData.balance.balance).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 8,
                            }
                          )}{" "}
                          {network === "Bitcoin Testnet" ? "tBTC" : "BTC"}
                        </p>
                        <p className="mb-2 ml-auto mr-6 text-sm font-light text-left text-black md:my-0 md:mx-0 md:flex-grow-default dark:text-newdarkmode-900 md:dark:text-gray-300">
                          {(
                            Number(lnWalletData.balance.balance) * conversion
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 3,
                          })}{" "}
                          USD
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs
                view={view}
                setView={setView}
                className=""
                assetsLen={assets.length}
                activitiesLen={activities.length}
                collectionsLen={collections.length}
              />
            </div>
            {view === 0 && (
              <>
                {activities.length ? (
                  <div className="w-full h-auto py-3 mx-auto xs:py-4">
                    <div className="flex flex-col w-full gap-3 xs:gap-4 h-auto px-4 xs:px-1.5 mx-auto bg-transparent">
                      {activities.map((activity) => (
                        <button
                          key={activity.id}
                          className="flex w-full p-2 bg-gray-200 border-gray-300 rounded-lg shadow-sm hover:shadow-lg border-1/2 dark:border-newdarkmode-800 dark:bg-newdarkmode-800 dark:bg-opacity-50 hover:bg-gray-300 dark:hover:bg-newdarkmode-800 flex-nowrap"
                          type="button"
                          onClick={() => {
                            if (!activity.lightning) {
                              navigate("/viewtransaction", {
                                state: {
                                  walletData,
                                  transaction: activity,
                                  vault,
                                  conversion,
                                  locationHistory: [window.location.hash],
                                },
                              });
                            }
                          }}
                          data-test-id={TEST_IDS.wallet.activity(activity.id)}
                        >
                          <div className="text-left grid grid-cols-1">
                            <div className="flex flex-row">
                              <div className="mr-1.5">
                                {activity.lightning ? (
                                  <Lightning className="w-4 h-4" />
                                ) : (
                                  <div className="my-auto text-sm font-semibold text-black dark:text-blue-500">
                                    L1
                                  </div>
                                )}
                              </div>
                              <div className="my-auto text-xs font-light text-black dark:text-gray-500 pl-1.5">
                                {new Date(activity.date * 1000).toDateString()}{" "}
                                {new Date(
                                  activity.date * 1000
                                ).toLocaleTimeString("en-US", {
                                  hour12: true,
                                  hour: "numeric",
                                  minute: "numeric",
                                })}
                              </div>
                            </div>
                            <div className="flex mt-1 text-base font-semibold text-white flex-nowrap">
                              {activity.action === "sent" ? (
                                <ArrowUpCircle
                                  className={classNames(
                                    activity.lightning
                                      ? "text-yellow-500"
                                      : "text-blue-500",
                                    "w-8 h-8 my-auto dark:text-opacity-75"
                                  )}
                                />
                              ) : (
                                <ArrowDownCircle
                                  className={classNames(
                                    activity.lightning
                                      ? "text-yellow-500"
                                      : "text-blue-500",
                                    "w-8 h-8 my-auto dark:text-opacity-75"
                                  )}
                                />
                              )}{" "}
                              <div className="my-auto ml-2 text-base font-normal text-black dark:text-gray-300">
                                {activity.action}{" "}
                                {activity.amount <= 999_999
                                  ? activity.amount.toLocaleString()
                                  : getLargeNumber(activity.amount)}{" "}
                                {activity.assetType}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col my-auto ml-auto text-right">
                            <span
                              className={classNames(
                                activity.status === "confirmed"
                                  ? "dark:text-green-500"
                                  : "dark:text-yellow-500",
                                "text-xs font-normal dark:font-light text-black mb-2"
                              )}
                            >
                              {activity.status}
                            </span>
                            <span className="text-xs font-thin text-gray-700 xs:text-sm dark:text-gray-500">
                              {Number(
                                (Number(activity.amount) / 100_000_000) *
                                  conversion
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 3,
                              })}{" "}
                              USD
                            </span>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center w-full h-full m-auto flex-grow-default">
                    <div className="flex flex-col justify-center w-full h-full">
                      <h3 className="p-6 text-base text-center text-gray-600 sm:text-lg">
                        No activities in wallet
                      </h3>
                    </div>
                  </div>
                )}
              </>
            )}
            {view === 1 && (
              <>
                {assets.length ? (
                  <div className="h-auto py-3 bg-transparent xs:py-4 flex-grow-default">
                    <div className="flex flex-col w-full h-auto px-4 xs:px-1.5 gap-3 xs:gap-4 mx-auto bg-transparent">
                      {assets.map((asset) => (
                        <button
                          key={asset.id}
                          type="button"
                          onClick={() =>
                            navigate("/assetdetails", {
                              state: {
                                walletData,
                                vault,
                                asset,
                                funded: tokensWalletFunded,
                                address: tokensWalletAddress,
                                udasWalletAddress,
                                locationHistory: [window.location.hash],
                              },
                            })
                          }
                          onKeyDown={(e) =>
                            e.key === "Enter"
                              ? navigate("/assetdetails", {
                                  state: {
                                    walletData,
                                    vault,
                                    asset,
                                    funded: tokensWalletFunded,
                                    address: tokensWalletAddress,
                                    locationHistory: [window.location.hash],
                                  },
                                })
                              : null
                          }
                          data-test-id={TEST_IDS.wallet.asset(asset.ticker)}
                          className="w-full p-3 text-gray-600 bg-gray-200 border-gray-300 rounded-lg shadow-sm dark:shadow-md grid grid-cols-2 hover:shadow-lg border-1/2 dark:border-newdarkmode-800 dark:bg-newdarkmode-800 dark:bg-opacity-50 hover:bg-gray-300 dark:hover:bg-newdarkmode-800 dark:text-gray-500"
                        >
                          <div className="w-full mr-auto text-left col-span-1 gap-6">
                            <div className="text-base font-semibold text-gray-900 truncate dark:text-gray-300">
                              {asset.ticker}
                            </div>
                            {/* It's impossible to know the value of an asset without a market.
                            <div className="m-auto text-sm text-black  dark:text-gray-500">
                              {asset.dollarBalance
                                ? `$ ${asset.dollarBalance.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 3,
                                    }
                                  )}USD`
                                : "value unknown"}
                                  </div> */}
                          </div>
                          <div className="flex flex-row w-full my-auto ml-auto col-span-1">
                            <div className="w-auto ml-auto text-base font-normal text-right text-gray-900 truncate flex-shrink-default dark:text-gray-300">
                              {asset.balance?.toLocaleString()} {asset.ticker}
                            </div>
                            <ChevronLeft className="flex-initial w-6 h-6 my-auto ml-3 rotate-180" />
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center w-full h-full m-auto flex-grow-default">
                    <div className="flex flex-col justify-center w-full h-full">
                      <p className="p-6 pb-2 text-base text-center text-gray-600 sm:text-lg dark:text-gray-600">
                        No assets in wallet
                      </p>
                      <p className="p-6 pt-2 text-base text-center text-gray-600 sm:text-lg dark:text-gray-600">
                        Click import assets to add assets to wallet
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
            {view === 2 && (
              <>
                {collections.length ? (
                  <div className="w-full h-auto py-3 bg-transparent xs:py-4">
                    <div className="flex flex-col w-full h-auto gap-3 xs:gap-4 px-4 xs:px-1.5">
                      {collections.map((collection) => (
                        <Disclosure key={collection.name} as="div" className="">
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex w-full px-3 py-1.5 text-left bg-gray-200 border-gray-300 rounded-lg shadow-sm dark:shadow-md hover:shadow-lg border-1/2 dark:border-newdarkmode-800 dark:bg-newdarkmode-800 dark:bg-opacity-50 hover:bg-gray-300 dark:hover:bg-newdarkmode-800 flex-nowrap">
                                {collection.udas[0].mediaType ===
                                  "audio/mpeg" && (
                                  <>
                                    {collection.logo ? (
                                      <img
                                        src={collection.logo}
                                        className="object-cover w-10 h-10 m-auto rounded-full"
                                        alt="unique digital asset"
                                      />
                                    ) : (
                                      <img
                                        src="/images/defaultAudioCoverArt.webp"
                                        className="object-cover w-10 h-10 m-auto rounded-full"
                                        alt="unique digital asset"
                                      />
                                    )}
                                  </>
                                )}
                                {collection.udas[0].mediaType.includes(
                                  "image"
                                ) && (
                                  <img
                                    src={
                                      collection.logo
                                        ? collection.logo
                                        : collection.udas[0].media
                                    }
                                    className="object-cover w-10 h-10 m-auto rounded-full"
                                    alt="unique digital asset"
                                  />
                                )}
                                {collection.udas[0].mediaType ===
                                  "video/mp4" && (
                                  <>
                                    {collection.logo ? (
                                      <img
                                        src={collection.logo}
                                        className="object-cover w-10 h-10 m-auto rounded-full"
                                        alt="unique digital asset"
                                      />
                                    ) : (
                                      <img
                                        src="/images/defaultAudioCoverArt.webp"
                                        className="object-cover w-10 h-10 m-auto rounded-full"
                                        alt="unique digital asset"
                                      />
                                    )}
                                  </>
                                )}
                                <span className="m-auto ml-2 text-base font-bold truncate flex-grow-default">
                                  {collection.name}
                                </span>
                                <span className="pl-3 m-auto ml-auto mr-3 text-sm font-thin text-right text-black dark:text-gray-400 whitespace-nowrap">
                                  {collection.udas.length} asset
                                  {collection.udas.length > 1 ? "s" : ""}
                                </span>
                                <ChevronUpIcon
                                  className={`${
                                    open
                                      ? "transform rotate-180"
                                      : "transform rotate-90"
                                  } w-6 h-6 text-gray-600 dark:text-gray-400 m-auto`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel>
                                <div className="pt-1.5 grid grid-cols-3 xs:pt-4">
                                  {collection.udas.map((uda) => (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        navigate("/uda", {
                                          state: {
                                            walletData,
                                            vault,
                                            uda,
                                            locationHistory: [
                                              window.location.hash,
                                            ],
                                          },
                                        })
                                      }
                                    >
                                      {uda.mediaType === "audio/mpeg" &&
                                        !uda.audioCoverArt && (
                                          <img
                                            key={uda.id}
                                            src="/images/defaultAudioCoverArt.webp"
                                            className="object-cover w-24 h-24 m-auto my-1.5 sm:my-6 rounded-lg sm:w-44 sm:h-44"
                                            alt="unique digital asset"
                                          />
                                        )}
                                      {uda.mediaType === "audio/mpeg" &&
                                        uda.audioCoverArt && (
                                          <img
                                            key={uda.id}
                                            src={uda.audioCoverArt}
                                            className="object-cover w-24 h-24 m-auto my-1.5 sm:my-6 rounded-lg sm:w-44 sm:h-44"
                                            alt="unique digital asset"
                                          />
                                        )}
                                      {uda.mediaType.includes("image") && (
                                        <img
                                          key={uda.id}
                                          src={uda.media}
                                          className="object-cover w-24 h-24 m-auto my-1.5 sm:my-6 rounded-lg sm:w-44 sm:h-44"
                                          alt="unique digital asset"
                                        />
                                      )}
                                      {uda.mediaType === "video/mp4" && (
                                        <video
                                          autoPlay
                                          loop
                                          muted
                                          playsInline
                                          crossOrigin="anonymous"
                                          controlsList="nodownload"
                                          key={uda.id}
                                          src={uda.media}
                                          className="object-cover w-24 h-24 m-auto my-1.5 sm:my-6 rounded-lg sm:w-44 sm:h-44"
                                        />
                                      )}
                                    </button>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col justify-center w-full h-full m-auto flex-grow-default">
                    <div className="flex flex-col justify-center w-full h-full">
                      <p className="p-6 pb-2 text-base text-center text-gray-600 sm:text-lg dark:text-gray-600">
                        No UDAs in wallet
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {view !== 0 && (
          <div className="w-full mx-auto 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
            <AssetFooter
              vault={vault}
              walletData={walletData}
              setOpenFundAssetsModal={setOpenFundAssetsModal}
              tokensWalletFunded={tokensWalletFunded}
              loadingVault={loadingVault}
              view={view}
            />
          </div>
        )}
        <Modal open={openFundAssetsModal} setOpen={setOpenFundAssetsModal}>
          <FundAssets
            walletBalance={addBalance(walletData?.balance)}
            tokensWalletAddress={tokensWalletAddress}
            udasWalletAddress={udasWalletAddress}
            vault={vault}
            setOpenFundAssetsModal={setOpenFundAssetsModal}
          />
        </Modal>
        <Modal open={openVersionPopup} setOpen={setOpenVersionPopup}>
          <ReleaseNotes version={VERSION} releaseNotes={releaseNotes} />
        </Modal>
        <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
      </div>
    );
  }
  return (
    <div className="flex justify-center w-full h-auto m-auto text-gray-900 min-h-page dark:text-gray-300">
      <Loader className="w-20 h-20 m-auto" />
    </div>
  );
};

export default Wallet;

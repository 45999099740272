/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { classNames } from "src/Hooks/util";

const { CONTEXT = "" } = process.env;

const promos = ["MIXD Future Factory"];

const ReleaseNotes = ({ ...props }) => (
  <div className="p-6 xs:p-9 dark:bg-transparent md:p-12">
    {!promos.includes(CONTEXT) && (
      <h1 className="text-xl border-gray-700 xs:text-3xl dark:text-white">
        BitMask v{props.version} release notes
      </h1>
    )}
    <ul
      className={classNames(
        promos.includes(CONTEXT) ? "" : "pt-6",
        "flex flex-col space-y-3"
      )}
    >
      {props.releaseNotes.map((note) => (
        <li
          className={classNames(
            promos.includes(CONTEXT) ? "text-center" : "text-left list-disc",
            "text-base font-light dark:text-gray-400 sm:text-lg lg:text-xl"
          )}
          key={JSON.stringify(note)}
        >
          {note}
        </li>
      ))}
    </ul>
  </div>
);

export default ReleaseNotes;

import React from "react";

import { classNames } from "src/Hooks/util";

const TextArea = ({ className = "", ...props }) => (
  <div className="my-4">
    <h3 className="mb-2 text-sm font-semibold text-left text-gray-800 dark:text-gray-400">
      {props.label}
    </h3>
    <textarea
      className={classNames(
        className,
        "bg-gray-200 focus:bg-gray-300 rounded-md text-md focus:outline disabled:opacity-50 disabled:cursor-not-allowed mx-auto focus:text-gray-900 placeholder-gray-600 dark:placeholder-gray-400 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-600 focus:ring-1 focus:ring-gray-500 dark:focus:ring-newdarkmode-600 text-base",
        props.darkest ? "dark:bg-newdarkmode-800" : "dark:bg-newdarkmode-700"
      )}
      {...props}
    />
  </div>
);

export default TextArea;

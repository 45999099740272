/* eslint-disable camelcase */

import {
  GetEncryptedWallet,
  GetMnemonicSeed,
  SaveMnemonicSeed,
  GetWalletData,
  // ListAssets,
  ImportAsset,
  GetBlindedUtxo,
  SendSats,
  FundVault,
  TransfersSerializeResponse,
  // ValidateTransfer,
  AcceptTransaction,
  CreateAsset,
  GetAssetsVault,
  CreateWalletResponse,
  AuthResponse,
  Account,
  AddInvoiceResponse,
  LnTransaction,
  PayInvoiceResponse,
  AssetTransfer,
  CheckPaymentResponse,
  SignPsbt,
} from "src/types/core";

import * as backend from "bitmask-core";

export const get_encrypted_wallet = async (
  password: string,
  encrypted_descriptors: string
): Promise<GetEncryptedWallet> =>
  JSON.parse(
    await backend.get_encrypted_wallet(password, encrypted_descriptors)
  );

export const get_mnemonic_seed = async (
  encryption_password: string,
  seed_password: string
): Promise<GetMnemonicSeed> =>
  JSON.parse(
    await backend.get_mnemonic_seed(encryption_password, seed_password)
  );

export const save_mnemonic_seed = async (
  mnemonic: string,
  encryption_password: string,
  seed_password: string
): Promise<SaveMnemonicSeed> =>
  JSON.parse(
    await backend.save_mnemonic_seed(
      mnemonic,
      encryption_password,
      seed_password
    )
  );

export const get_wallet_data = async (
  descriptor: string,
  change_descriptor?: string
): Promise<GetWalletData> =>
  JSON.parse(await backend.get_wallet_data(descriptor, change_descriptor));

// export const list_assets = async (
//   xpubkh: string,
//   encryption_secret: string
// ): Promise<ListAssets> => JSON.parse(await backend.list_assets(xpubkh, encryption_secret));

export const import_asset = async (
  asset: string,
  utxo: string
): Promise<ImportAsset> => JSON.parse(await backend.import_asset(asset, utxo));

export const get_blinded_utxo = async (
  utxo_string: string
): Promise<GetBlindedUtxo> =>
  JSON.parse(await backend.get_blinded_utxo(utxo_string));

export const send_sats = async (
  descriptor: string,
  change_descriptor: string,
  address: string,
  amount: bigint,
  fee_rate: number
): Promise<SendSats> =>
  JSON.parse(
    await backend.send_sats(
      descriptor,
      change_descriptor,
      address,
      amount,
      fee_rate
    )
  );

export const fund_vault = async (
  descriptor: string,
  change_descriptor: string,
  address: string,
  uda_address: string,
  asset_amount: bigint,
  uda_amount: bigint,
  fee_rate: number
): Promise<FundVault> =>
  JSON.parse(
    await backend.fund_vault(
      descriptor,
      change_descriptor,
      address,
      uda_address,
      asset_amount,
      uda_amount,
      fee_rate
    )
  );

export const get_assets_vault = async (
  rgb_assets_descriptor_xpub: string,
  rgb_udas_descriptor_xpub: string
): Promise<GetAssetsVault> =>
  JSON.parse(
    await backend.get_assets_vault(
      rgb_assets_descriptor_xpub,
      rgb_udas_descriptor_xpub
    )
  );

export const create_asset = async (
  ticker: string,
  name: string,
  precision: number,
  supply: bigint,
  utxo: string
): Promise<CreateAsset> =>
  JSON.parse(await backend.create_asset(ticker, name, precision, supply, utxo));

export const transfer_assets = async (
  descriptor_xpub: string,
  transfers: AssetTransfer[]
): Promise<TransfersSerializeResponse> =>
  JSON.parse(await backend.transfer_assets({ descriptor_xpub, transfers }));

export const sign_psbt = async (
  rgb_descriptor_xprv: string,
  psbt: string
): Promise<SignPsbt> =>
  JSON.parse(await backend.sign_psbt(rgb_descriptor_xprv, psbt));

// export const validate_transfer = async (
//   utxo_string: string
// ): Promise<ValidateTransfer> => JSON.parse(await backend.validate_transfer(utxo_string));

export const accept_transfer = async (
  consignment: string,
  blinding: string,
  outpoint: string,
  blinded: string
): Promise<AcceptTransaction> =>
  JSON.parse(
    await backend.accept_transfer(consignment, blinding, outpoint, blinded)
  );

export const get_network = async (): Promise<string> =>
  JSON.parse(await backend.get_network());

export const get_endpoint = async (path: string): Promise<string> =>
  JSON.parse(await backend.get_endpoint(path));

export const switch_network = async (network: Network): Promise<void> =>
  backend.switch_network(network.toString());

export const switch_host = async (host: string): Promise<void> =>
  backend.switch_host(host);

export const ln_create_wallet = async (
  username: string,
  password: string
): Promise<CreateWalletResponse> =>
  JSON.parse(await backend.ln_create_wallet(username, password));

export const ln_auth = async (
  username: string,
  password: string
): Promise<AuthResponse> =>
  JSON.parse(await backend.ln_auth(username, password));

export const ln_create_invoice = async (
  description: string,
  amount: number,
  token: string
): Promise<AddInvoiceResponse> =>
  JSON.parse(await backend.ln_create_invoice(description, amount, token));

export const ln_get_balance = async (token: string): Promise<Account> =>
  JSON.parse(await backend.ln_get_balance(token));

export const ln_get_txs = async (token: string): Promise<LnTransaction[]> =>
  JSON.parse(await backend.ln_get_txs(token));

export const ln_pay_invoice = async (
  payment_request: string,
  token: string
): Promise<PayInvoiceResponse> =>
  JSON.parse(await backend.ln_pay_invoice(payment_request, token));

export const ln_check_payment = async (
  payment_hash: string
): Promise<CheckPaymentResponse> =>
  JSON.parse(await backend.ln_check_payment(payment_hash));

// JavaScript is a dumpster fire: https://github.com/typescript-eslint/typescript-eslint/issues/325
// eslint-disable-next-line
export enum Network {
  // eslint-disable-next-line
  bitcoin = "bitcoin",
  // eslint-disable-next-line
  testnet = "testnet",
  // eslint-disable-next-line
  signet = "signet",
  // eslint-disable-next-line
  regtest = "regtest",
}

switch_host(process.env.BITMASK_NODE_HOST || "");
const network = Network[process.env.BITCOIN_NETWORK || ""];
console.debug("BITCOIN_NETWORK:", process.env.BITCOIN_NETWORK);
console.debug("Network:", network?.toString());
switch_network(network);

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import * as backend from "src/Backend";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import ErrorModal from "src/Components/Modals/Error";
import { getConversion, lndhubError } from "src/Hooks/util";
import { Location } from "src/types";
import Mixpanel from "src/lib/Mixpanel";

const LightningConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { wallet, vault, lnCredentials, lnInvoice } = location.state;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [conversion, setConversion] = useState(0);

  const amount =
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    require("light-bolt11-decoder").decode(lnInvoice).sections[2].value * 0.001;

  const handlePayLightningInvoice = async () => {
    try {
      setLoading(true);
      const invoice = await backend.ln_pay_invoice(
        lnInvoice,
        lnCredentials.accessToken
      );
      if (invoice.error) {
        setError({
          title: "Error Creating LN Invoice",
          message: lndhubError(invoice.error),
        });
        setOpen(true);
      } else if (invoice) {
        navigate("/success", {
          state: {
            wallet,
            vault,
            lnCredentials,
            transaction: { assetType: "Lightning" },
          },
        });
      }
      Mixpanel.track("Viewed LN Transaction Confirmation Page", {
        Transaction: invoice,
      });
    } catch (err) {
      if (err instanceof Error) {
        setError({
          title: "Error Paying LN Invoice",
          message: err.message,
        });
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    (async () => {
      const rate = await getConversion();
      setConversion(rate);
    })();
  }, []);

  return (
    <PageWrapper
      title="Confirm Lightning Transaction"
      handlePageBack={() =>
        navigate("/wallet", {
          state: { wallet, vault, lnCredentials },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet, vault, lnCredentials },
        })
      }
      handleSubmit={() => handlePayLightningInvoice()}
      loading={loading}
      confirmation
    >
      <div className="p-3 bg-gray-300 rounded-lg shadow-lg dark:bg-newdarkmode-700 sm:p-6 lg:p-12">
        <div className="flex flex-col w-full p-3 py-6 m-auto text-center sm:w-11/12 md:w-10/12 xs:p-6 space-y-3">
          <h2 className="text-xl font-semibold text-black xs:text-2xl dark:text-gray-300">
            Pay LN Invoice for {Number(amount).toLocaleString()} SATS
          </h2>
          <h2 className="text-lg font-normal text-gray-800 dark:text-gray-300">
            {Number(Number(amount / 100_000_000)).toLocaleString(undefined, {
              minimumFractionDigits: 8,
            })}{" "}
            BTC
          </h2>
          <h2 className="text-lg font-normal text-gray-800 dark:text-gray-300">
            {Number(Number(amount / 100_000_000) * conversion).toLocaleString(
              undefined,
              { minimumFractionDigits: 3 }
            )}{" "}
            USD
          </h2>
        </div>
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default LightningConfirm;

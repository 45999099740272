/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import Telegram from "src/Components/Icons/Telegram";
import Twitter from "src/Components/Icons/Twitter";
import Discord from "src/Components/Icons/Discord";
import Instagram from "src/Components/Icons/Instagram";
import LogoDark from "src/Components/Icons/LogoDark";
import Heart from "src/Components/Icons/Heart";

const Footer = () => {
  const [, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");

  const postEmail = async () => {
    await fetch("/api/emailsignup", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
  };

  const onSubmit = () => {
    if (email !== "") {
      const re = /\S+@\S+\.\S+/;
      if (re.test(email)) {
        setError(false);
        postEmail().then(() => {
          setEmail("");
          setSuccess(true);
        });
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };

  return (
    <footer className="flex flex-col w-full h-auto px-10 pt-2 mt-auto mb-0 bg-gray-100 sm:px-12 lg:px-6 2xl:px-12 sm:pt-3 dark:bg-newdarkmode-900">
      <div className="md:mx-6 md:ml-10">
        <LogoDark className="w-24 h-20 my-auto mr-auto md:m-0 sm:mb-4" />
      </div>
      <div className="flex flex-col lg:flex-nowrap md:flex-wrap md:flex-row">
        <div className="flex-1 md:mx-6 md:ml-10">
          <div className="text-lg font-semibold text-gray-600 cursor-pointer dark:text-newdarkmode-600 hover:text-gray-900 dark:hover:text-gray-500 md:text-left">
            <div className="text-xl font-light text-gray-900 dark:text-gray-300">
              Get the latest updates from Bitmask
            </div>
            <div className="flex flex-col lg:flex-row xs:w-auto">
              <div className="flex flex-row xs:w-full xs:mx-auto 2xl:flex-row">
                <input
                  id="email"
                  name="email"
                  className="flex-auto w-auto h-12 py-3 pl-2 pr-3 my-4 mr-auto text-base font-light placeholder-gray-500 bg-gray-100 border-gray-500 rounded-lg min-w-52 xs:max-w-full sm:mr-4 xs:min-w-64 2xl:w-auto lg:max-w-96 dark:placeholder-gray-300 dark:bg-newdarkmode-700 dark:text-gray-300 dark:border-newdarkmode-600 border-1/2 focus:outline-none focus:text-gray-900 dark:focus:text-gray-300 focus:placeholder-gray-500 focus:ring-1 focus:ring-gray-600 dark:focus:ring-newdarkmode-600 sm:text-sm"
                  placeholder="Enter Email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value.trim());
                    setSuccess(false);
                  }}
                  onKeyDown={(e) => (e.key === "Enter" ? onSubmit() : null)}
                />
                {email !== "" && /\S+@\S+\.\S+/.test(email) === false && (
                  <p className="mb-4 text-sm font-thin tracking-wide text-gray-500 md:text-base xl:hidden">
                    Please enter a valid email address.
                  </p>
                )}
                <RoundedButton
                  onClick={() => onSubmit()}
                  className="flex w-auto mx-auto my-auto ml-3 font-bold text-black bg-yellow-500 rounded-lg h-11 md:px-14"
                >
                  Join
                </RoundedButton>
              </div>
              {email !== "" && /\S+@\S+\.\S+/.test(email) === false && (
                <p className="hidden mt-4 text-sm font-thin tracking-wide text-gray-500 md:text-base xl:block">
                  Please enter a valid email address
                </p>
              )}
              {success && (
                <p className="mt-4 text-sm font-thin tracking-wide text-gray-500 md:text-base">
                  Thank you! We will keep you in the loop.
                </p>
              )}
              <div className="hidden font-normal text-gray-600 cursor-pointer lg:flex sm:flex-nowrap md:ml-auto dark:text-white">
                <a
                  className="w-24 m-auto"
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/bitmask_app?s=21&t=VH4nq5qpcV5iPRa2Z4gEyA"
                >
                  <Twitter className="w-10 h-10 m-auto sm:w-10 sm:h-10" />
                </a>
                <a
                  className="w-24 m-auto"
                  target="_blank"
                  rel="noreferrer"
                  href="https://t.me/tryDIBA"
                >
                  <Telegram className="m-auto w-14 h-14 sm:w-14 sm:h-14" />
                </a>
                <a
                  className="w-24 m-auto"
                  target="_blank"
                  rel="noreferrer"
                  href="https://discord.gg/RzKxTJg24K"
                >
                  <Discord className="w-8 h-8 m-auto sm:w-8 sm:h-8" />
                </a>
                <a
                  className="w-24 m-auto"
                  target="_blank"
                  rel="noreferrer"
                  href="https://instagram.com/trydiba?igshid=YWJhMjlhZTc="
                >
                  <Instagram className="w-8 h-8 m-auto sm:w-8 sm:h-8" />
                </a>
              </div>
              <div className="flex w-full mt-10 mb-4 font-normal text-gray-600 cursor-pointer md:mx-auto md:mb-6 flex-nowrap  dark:text-white lg:hidden">
                <a
                  href="https://discord.gg/rVhJcKw3yn"
                  className="w-24 m-auto"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Twitter className="w-10 h-10 m-auto sm:w-10 sm:h-10" />
                </a>
                <a
                  href="https://discord.gg/rVhJcKw3yn"
                  className="w-24 m-auto"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Telegram className="m-auto w-14 h-14 sm:w-14 sm:h-14" />
                </a>
                <a
                  href="https://discord.gg/rVhJcKw3yn"
                  className="w-24 m-auto"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Discord className="w-8 h-8 m-auto sm:w-8 sm:h-8" />
                </a>
                <a
                  href="https://www.instagram.com/tryDIBA/"
                  className="w-24 m-auto"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Instagram className="w-8 h-8 m-auto sm:w-8 sm:h-8" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="block w-full pt-3 my-auto text-lg font-light text-center text-gray-600 cursor-pointer dark:text-white sm:hidden">
        <div className="flex justify-center flex-nowrap">
          <span className="flex text-lg italic font-semibold">
            <span className="my-auto mr-3 text-gray-500 whitespace-nowrap">
              built with
            </span>
            <span>
              <Heart className="w-8 h-8 text-yellow-500" />
            </span>
            <span className="my-auto ml-2 mr-4">by</span>
          </span>
          <a
            href="https://diba.io"
            target="_blank"
            rel="noreferrer"
            className="w-24 h-auto my-auto"
          >
            <LogoDark className="w-24 h-auto text-black dark:text-white" />
          </a>
        </div>{" "}
      </div>
      <div className="flex flex-col w-full my-auto mt-8 mb-6 lg:flex-row gap-9">
        <div className="flex-wrap hidden mx-auto sm:justify-center sm:flex sm:flex-nowrap lg:mr-auto lg:ml-10 lg:my-auto">
          <div className="flex w-auto my-auto md:my-0 flex-nowrap">
            <span className="flex m-auto text-lg italic font-semibold flex-nowrap">
              <span className="m-auto text-gray-500 whitespace-nowrap">
                Built with
              </span>
              <span>
                <Heart className="w-8 h-8 m-auto mx-2 text-yellow-500" />
              </span>
              <span className="m-auto text-gray-500">by</span>
            </span>
            <a
              href="https://diba.io"
              className="w-24 h-10 m-auto mb-1 ml-3 md:h-12 md:mb-auto"
              target="_blank"
              rel="noreferrer"
            >
              <LogoDark className="w-24 h-12 text-black dark:text-white" />
            </a>
          </div>
        </div>
        <div className="flex flex-row justify-center mx-auto gap-6 lg:mr-10 sm:my-auto sm:gap-8">
          <div className="flex flex-col">
            <span className="mx-auto text-gray-500">
              <a href="https://diba.io" target="_blank" rel="noreferrer">
                <p className="text-sm">Privacy Policy</p>
              </a>
            </span>
          </div>
          <div className="flex flex-col">
            <span className="mx-auto text-gray-500">
              <a href="https://diba.io" target="_blank" rel="noreferrer">
                <p className="text-sm">Terms of Service</p>
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

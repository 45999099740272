/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* global chrome */
import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ReactGA from "react-ga";

import * as backend from "src/Backend";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import ArrowRight from "src/Components/Icons/ArrowRight";
import { NetworkContext } from "src/Hooks/NetworkContext";
import ErrorModal from "src/Components/Modals/Error";
import lib, { isExtension } from "src/lib";
import { updatingAssets } from "src/aux/updateAssets";
import { getConversion, classNames } from "src/Hooks/util";
import { Activity, Transaction, Location, Collection } from "src/types";
import { ImportAsset, TransferAssets } from "src/types/core";
import Mixpanel from "src/lib/Mixpanel";
import getBlinding from "src/lib/getBlinding";
import Loader from "src/Components/Loaders/Loader";

// TODO: clarify Asset, Assets and token naming

const updateActivities = async (transaction: Transaction, wallet: string) => {
  if (
    transaction.assetType === "Asset" ||
    transaction.assetType === "UDA" ||
    transaction.assetType === "Assets" ||
    transaction.assetType === "token"
  ) {
    const activity: Activity = {
      date: Date.now(),
      id: uuidv4(),
      action: "sent",
      status: "pending",
      ...transaction,
    };
    const key = `${wallet}.asset_activities`;
    const result = await lib.storageGet<Activity[]>([key]);
    let activities: Activity[] = [];
    if (result[key]) {
      activities = result[key];
    }
    activities.push(activity);
    await lib.storageSet({ [key]: activities });
    console.debug(`Value is set to ${activities}`);
  }
};

const Confirm = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    transaction: transactionBase,
    walletData,
    vault,
    asset,
    locationHistory,
  } = location.state;

  const [blinding, setBlinding] = useState({
    utxo: "",
    conceal: "",
    blinding: "",
  });
  const { network } = useContext(NetworkContext);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [conversion, setConversion] = useState(0);

  const transaction = {
    ...transactionBase,
    amount: transactionBase.assetType === "UDA" ? "1" : transactionBase.amount,
  };

  useEffect(() => {
    if (!location.state.blinding) {
      const setBlindingProperty = async () => {
        setBlinding(
          await getBlinding(
            walletData.name,
            vault.rgbAssetsDescriptorXpub,
            true
          )
        );
      };

      // call the function
      setBlindingProperty()
        // make sure to catch any error
        .catch(console.error);
    } else {
      setBlinding(location.state.blinding);
    }
  }, []);

  useEffect(() => {
    ReactGA.pageview("Confirm");
    if (transactionBase.assetType === "token")
      Mixpanel.track("Viewed Send Asset Confirmation", {
        Transaction: transactionBase,
        Asset: asset,
      });
    Mixpanel.track("Viewed Transaction Confirmation Page", {
      Transaction: transactionBase,
    });
  }, []);

  const handleSubmit = async () => {
    try {
      if (
        transaction.assetType === "Asset" ||
        transaction.assetType === "token" ||
        transaction.assetType === "UDA" ||
        transaction.assetType === "Assets"
      ) {
        setLoading(true);
        if (!transaction.recipient?.conceal) {
          throw new Error("No blinded UTXO for transaction recipient provided");
        }
        if (!transaction.asset) {
          throw new Error("No transaction asset provided");
        }
        const assetsVaultDetails = await backend.get_assets_vault(
          vault.rgbAssetsDescriptorXpub,
          vault.rgbUdasDescriptorXpub
        );
        if (!assetsVaultDetails.assets_output) {
          throw new Error("No RGB assets output");
        }
        if (!assetsVaultDetails.assets_change_output) {
          throw new Error("No RGB assets change output");
        }
        if (!assetsVaultDetails.udas_change_output) {
          throw new Error("No RGB assets change output");
        }
        if (!assetsVaultDetails.udas_output) {
          throw new Error("No RGB assets change output");
        }

        const descriptorXpub =
          transaction.assetType === "UDA"
            ? vault.rgbUdasDescriptorXpub
            : vault.rgbAssetsDescriptorXpub;
        const descriptorXprv =
          transaction.assetType === "UDA"
            ? vault.rgbUdasDescriptorXprv
            : vault.rgbAssetsDescriptorXprv;
        const output =
          transaction.assetType === "UDA"
            ? assetsVaultDetails.udas_output
            : assetsVaultDetails.assets_output;
        const changeOutput =
          transaction.assetType === "UDA"
            ? assetsVaultDetails.udas_change_output
            : assetsVaultDetails.assets_change_output;
        // const blindedChangeUtxo = await backend.get_blinded_utxo(changeOutput);

        const { psbt, declare }: TransferAssets = await backend.transfer_assets(
          descriptorXpub,
          [
            {
              asset_contract: asset.genesis,
              asset_utxo: {
                outpoint: output,
                terminal_derivation: "/0/0",
                commitment: "",
              },
              asset_amount: BigInt(transaction.amount),
              change_utxo: changeOutput,
              beneficiaries: [
                `${transaction.amount}@${transaction.recipient.conceal}`,
              ],
            },
          ]
        );
        const { transfers } = declare;
        const { consignment } = transfers[0]; // TODO: Reveal needs to be kept also?

        const tx = await backend.sign_psbt(descriptorXprv, psbt);

        console.info("Asset transfer signed tx data:", tx);
        transaction.id = tx.txid;

        updateActivities(transaction, walletData.name);

        if (transaction.assetType === "UDA") {
          const collection = ""; // TODO: name collection
          const key = `${walletData.name}.collections`;
          console.log(key);
          chrome.storage.local.get([key], (result) => {
            if (result[key] && result[key].length > 0) {
              let collections: Collection[] = result[key];
              collections = collections.map((col) => {
                const newcol: Collection = {
                  id: col.id,
                  name: col.name,
                  udas: col.udas.filter((uda) => uda.id !== asset.id),
                };
                return newcol;
              });
              chrome.storage.local.set({ [key]: collections }, () => {
                console.log(`Value is set to ${collections}`);
              });
            }
          });
          ReactGA.event({
            category: "Transfer",
            action: "Transfer an UDA",
            label: asset.id,
          });
        } else {
          // eslint-disable-next-line no-lonely-if
          if (blinding.utxo !== "") {
            const assetData: ImportAsset = await backend.import_asset(
              asset.id,
              blinding.utxo
            );

            const futureAssets = await updatingAssets(walletData, assetData);
            console.debug(`Value is set to ${futureAssets}`);

            ReactGA.event({
              category: "Transfer",
              action: "Transfer an asset",
              label: asset.id,
            });
          }
        }

        navigate("/success", {
          state: {
            wallet: walletData.name,
            vault,
            transaction,
            consignment,
          },
        });
      } else {
        setLoading(true);

        if (!transaction.recipient?.address) {
          throw new Error("No address for transaction recipient provided");
        }

        await backend.send_sats(
          vault.btcDescriptorXprv,
          vault.btcChangeDescriptorXprv,
          transaction.recipient.address,
          BigInt(transaction.amount),
          1.1 // TODO: configurable fee rate
        );

        updateActivities(transaction, walletData.name);
        ReactGA.event({
          category: "Transfer",
          action: "Transfer bitcoin",
        });
        navigate("/success", {
          state: { wallet: walletData.name, vault, transaction },
        });
      }
    } catch (err: any) {
      setLoading(false);
      if (err.toString().includes("InsufficientFunds")) {
        setError({
          title: `Error sending payment`,
          message: err,
        });
      } else if (err.toString().includes("Error on address")) {
        setError({
          title: `Error sending payment`,
          message: "Invalid address.",
        });
      } else {
        setError({
          title: `Error sending payment`,
          message: JSON.stringify(err),
        });
      }
      setOpen(true);
    }
  };

  useEffect(() => {
    (async () => {
      const rate = await getConversion();
      setConversion(rate);
    })();
  }, []);

  if (!blinding) {
    return (
      <div
        className={classNames(
          isExtension ? "min-w-[400px]" : "min-w-80",
          "flex justify-center w-full h-full m-auto text-gray-900 dark:text-gray-300 bg-gray-100 dark:bggradientlanding"
        )}
      >
        <Loader className="w-20 h-20 m-auto" />
      </div>
    );
  }

  return (
    <PageWrapper
      title="Confirm Transaction"
      handlePageBack={() =>
        navigate(locationHistory.pop()?.replace("#", "") || "/", {
          state: {
            ...location.state,
          },
        })
      }
      handleCancel={() =>
        navigate("/wallet", { state: { wallet: walletData.name, vault } })
      }
      handleSubmit={() => handleSubmit()}
      loading={loading}
      confirmation
    >
      <div className="sm:bg-gray-300 sm:dark:bg-newdarkmode-800 sm:rounded-lg sm:shadow-lg sm:p-12">
        <div className="w-8/12 p-6 m-auto mb-6 text-center bg-gray-300 rounded-lg shadow-lg dark:bg-newdarkmode-800 sm:shadow-md sm:mb-12">
          <h2 className="text-2xl font-semibold text-black dark:text-gray-300">
            {transaction.assetType !== "UDA" &&
              Number(transaction.amount).toLocaleString()}{" "}
            {asset.ticker}
          </h2>
          <h2 className="text-base font-normal text-gray-800 dark:text-gray-500">
            {Number(
              (Number(transaction.amount) / 100_000_000) * conversion
            ).toLocaleString(undefined, { minimumFractionDigits: 3 })}{" "}
            USD
          </h2>
        </div>
        <div className="mt-6 grid grid-cols-7">
          <div className="w-full col-span-3">
            <h3 className="mb-1 text-sm font-semibold text-gray-700 sm:text-base dark:text-gray-400">
              From
            </h3>
            <div className="flex w-auto h-12 px-2 my-auto mt-1 text-left rounded-lg flex-nowrap">
              <p className="my-auto mr-2 text-base font-medium text-black sm:text-lg dark:text-gray-300">
                {transaction.sender?.name}:{" "}
              </p>
              <p className="my-auto text-base font-normal text-gray-800 truncate sm:text-lg dark:text-gray-400">
                {transaction.sender?.address}
              </p>
            </div>
          </div>
          <div className="m-auto col-span-1">
            <ArrowRight className="mt-6 text-black w-7 h-7 dark:text-gray-500" />
          </div>
          <div className="w-full col-span-3">
            <h3 className="mb-1 text-sm font-semibold text-gray-700 sm:text-base dark:text-gray-400">
              To
            </h3>
            <div className="flex w-auto h-12 px-2 my-auto mt-1 text-left rounded-lg flex-nowrap">
              <p className="my-auto mr-2 text-base font-medium text-black sm:text-lg dark:text-gray-300">
                {transaction.recipient?.name}:{" "}
              </p>
              <p className="my-auto text-base font-normal text-gray-800 truncate sm:text-lg dark:text-gray-400">
                {transaction.recipient?.address
                  ? transaction.recipient?.address
                  : transaction.recipient?.conceal}
              </p>
            </div>
          </div>
        </div>
        {network !== "Bitcoin Testnet" && (
          <>
            <h3 className="mt-4 mb-1 text-sm font-semibold text-gray-600 dark:text-gray-500">
              Transaction Fee
            </h3>
            <div className="grid grid-cols-2">
              <div className="mr-5">
                <div className="flex flex-col w-auto px-2 py-1 m-auto mt-1 text-center rounded-lg">
                  <p className="m-auto text-base text-black truncate ont-light dark:text-gray-300">
                    {Number(transaction.fee).toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        {transaction.assetType !== "UDA" && (
          <div className="mt-4 flex-grow-default">
            <h3 className="mb-1 text-sm font-semibold text-gray-700 sm:text-base dark:text-gray-400">
              Total
            </h3>
            <div className="flex w-auto px-1 py-3 m-auto mt-1 text-base text-left rounded-lg flex-nowrap sm:text-lg">
              <p className="my-auto font-semibold text-black truncate dark:text-white">
                {(
                  Number(transaction.amount) + transaction.fee
                ).toLocaleString()}{" "}
                {asset.ticker}
              </p>
              <p className="my-auto ml-auto font-semibold text-right text-gray-800 truncate dark:text-gray-300">
                {(
                  (Number(transaction.amount) / 100_000_000) * conversion +
                  (transaction.fee / 100_000_000) * conversion
                ).toLocaleString(undefined, { minimumFractionDigits: 3 })}{" "}
                USD
              </p>
            </div>
            <h3 className="mt-1 ml-1 text-sm font-medium text-gray-800 dark:text-gray-500">
              Amount + Fee
            </h3>
          </div>
        )}
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default Confirm;

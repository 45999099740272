/* eslint-disable no-unused-vars */
/* global chrome */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import Copy from "src/Components/Icons/Copy";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import ChevronLeft from "src/Components/Icons/ChevronLeft";
import Trash from "src/Components/Icons/Trash";
import { DeleteAsset } from "src/Components/Modals/DeleteAsset";
import ErrorModal from "src/Components/Modals/Error";
import FundAssets from "src/Components/Modals/Children/FundAssets";
import Modal from "src/Components/Modals/Modal";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import { Location } from "src/types";
import Mixpanel from "src/lib/Mixpanel";
import { addBalance } from "src/Hooks/util";
import CopyButton from "src/Components/Buttons/CopyButton";

const AssetDetails = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    walletData,
    vault,
    asset,
    funded,
    address,
    locationHistory,
    udasWalletAddress,
  } = location.state;
  const [openFundAssetsModal, setOpenFundAssetsModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [openCopyModal, setOpenCopyModal] = useState(false);

  useEffect(() => {
    ReactGA.pageview("AssetDetails");
    Mixpanel.track("Viewed Asset Details", { Asset: asset });
  }, []);

  return (
    <div className="flex justify-center w-11/12 h-auto mx-auto overflow-hidden min-h-page 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
      <div className="flex flex-col w-full mx-auto content-evenly">
        <div className="flex flex-row w-full h-auto mx-auto content-evenly">
          <button
            type="button"
            className="flex flex-row mt-3 mb-3 text-black cursor-pointer lg:mt-6 flex-nowrap dark:text-white dark:hover:text-gray-300 hover:text-gray-700"
            onClick={() =>
              navigate("/wallet", { state: { wallet: walletData.name, vault } })
            }
          >
            <ChevronLeft className="w-8 h-8 m-auto font-bold" />
            <h1 className="m-auto ml-3 text-2xl font-medium text-left flex-grow-default">
              Asset Details
            </h1>
          </button>
          <button
            type="button"
            onClick={() => {
              Mixpanel.track("Tapped Delete Asset");
              setOpen(true);
            }}
            className="my-auto ml-auto mr-3"
          >
            <Trash className="w-8 h-8 text-gray-800 dark:text-gray-300 dark:hover:text-gray-500 hover:text-gray-600 margin-auto" />
          </button>
        </div>
        <div className="flex flex-col justify-center w-11/12 h-auto m-auto xs:w-9/12 flex-grow-default">
          <div className="w-full p-6 m-auto text-black bg-gray-300 rounded-lg shadow-lg dark:text-gray-400 dark:bggradient sm:p-12">
            <div className="grid grid-cols-2">
              <p className="my-4 text-lg font-semibold text-left flex-grow-default">
                Asset Ticker:
              </p>
              <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
                {asset.ticker}
              </p>
            </div>
            <div className="grid grid-cols-2">
              <p className="my-4 text-lg font-semibold text-left flex-grow-default">
                Amount:
              </p>
              <p className="my-4 text-lg font-normal text-right truncate flex-grow-default">
                {asset.balance?.toLocaleString()}
              </p>
            </div>
          </div>
        </div>
        <CopyButton
          className="w-11/12 m-auto xs:w-9/12"
          title="asset genesis"
          handleOnClick={() => {
            navigator.clipboard.writeText(asset.genesis);
            setOpenCopyModal(true);
          }}
        >
          {asset.genesis}
        </CopyButton>
        <div className="w-11/12 py-6 mx-auto text-xl font-semibold xs:w-9/12 sm:pb-12 lg:pb-20">
          <div className="w-full m-auto mt-6 grid grid-cols-2">
            <RoundedButton
              className="text-base text-black bg-yellow-500"
              onClick={() => {
                Mixpanel.track("Tapped Send Asset");
                ReactGA.event({
                  category: "Asset details",
                  action: "Send",
                  label: asset.id,
                });
                navigate("/send", {
                  state: {
                    ...location.state,
                    locationHistory: [...locationHistory, window.location.hash],
                    type: "Asset",
                  },
                });
              }}
            >
              Send
            </RoundedButton>
            <RoundedButton
              className="ml-2 text-base text-black bg-yellow-500"
              onClick={() => {
                Mixpanel.track("Tapped Accept Asset");
                ReactGA.event({
                  category: "Asset Details",
                  action: "Accept",
                  label: asset.id,
                });
                navigate("/accepttransaction", {
                  state: {
                    ...location.state,
                    option: "Asset",
                    locationHistory: [...locationHistory, window.location.hash],
                  },
                });
              }}
            >
              Accept
            </RoundedButton>
          </div>
          <RoundedButton
            className="w-full m-auto mt-4 text-base text-black bg-yellow-500"
            onClick={() => {
              if (funded) {
                Mixpanel.track("Tapped Generate Asset Invoice", {
                  Asset: asset,
                });
                ReactGA.event({
                  category: "Asset Details",
                  action: "Generate Invoice",
                  label: asset.id,
                });
                // We will need to determine how to decern which asset type we are dealing with
                // and pass it to receive. Set as Asset for now.
                navigate("/receive", {
                  state: {
                    ...location.state,
                    type: "Asset",
                    locationHistory: [...locationHistory, window.location.hash],
                  },
                });
              } else {
                Mixpanel.track("Tapped Fund Vault");
                // If we don't have the necessary utxas in assets wallet, we say the user tu fund it
                setOpenFundAssetsModal(true);
              }
            }}
          >
            {funded ? "Generate Invoice" : "Fund assets wallet"}
          </RoundedButton>
        </div>
      </div>
      <Modal open={openFundAssetsModal} setOpen={setOpenFundAssetsModal}>
        <FundAssets
          walletBalance={addBalance(walletData?.balance)}
          vault={vault}
          udasWalletAddress={udasWalletAddress}
          tokensWalletAddress={address}
          setOpenFundAssetsModal={setOpenFundAssetsModal}
        />
      </Modal>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
      <DeleteAsset
        open={open}
        setOpen={setOpen}
        walletData={walletData}
        assetData={asset}
      />
      <ErrorModal
        open={openError}
        setOpen={setOpenError}
        message={error.message}
        title={error.title}
      />
    </div>
  );
};

export default AssetDetails;

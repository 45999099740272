import React from "react";

import { omitKeys } from "src/Hooks/util";
import SearchIcon from "src/Components/Icons/Search";

const Search = ({ className = "", ...props }) => (
  <div className="my-4">
    <label
      htmlFor="email"
      className="relative text-gray-400 focus-within:text-gray-600"
    >
      <i>
        <SearchIcon className="absolute w-6 h-6 text-gray-500 pointer-events-none top-1/2 transform -translate-y-1/2 left-3" />
      </i>
      <input
        type="text"
        placeholder="Search"
        onChange={(e) => props.setsearch(e.target.value)}
        className={`${className} pl-12 bg-gray-300 focus:bg-gray-100 dark:bg-newdarkmode-700 disabled:opacity-50 disabled:cursor-not-allowed rounded-md text-md focus:outline-none focus:text-gray-900 placeholder-gray-600 dark:placeholder-gray-400 dark:text-gray-500 dark:focus:text-gray-500 focus:placeholder-gray-500 focus:ring-1 focus:ring-gray-700 focus:border-gray-700 dark:focus:ring-newdarkmode-600 dark:focus:border-newdarkmode-600 text-base`}
        {...omitKeys(props, ["setsearch"])}
        disabled={props.disabled || false}
      />
    </label>
  </div>
);

export default Search;

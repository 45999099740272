/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* global chrome */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga";

import { TEST_IDS } from "tests/ids";

import * as backend from "src/Backend";
import TextField from "src/Components/Inputs/TextField";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import useWindowSize from "src/Hooks/useWindowSize";
import lib, { isExtension } from "src/lib";
import { Preload } from "src/types";
import {
  GetAssetsVault,
  GetEncryptedWallet,
  SaveMnemonicSeed,
} from "src/types/core";
import ErrorModal from "src/Components/Modals/Error";
import Mixpanel from "src/lib/Mixpanel";
import PinPad from "src/Components/Inputs/PinPad";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

const WalletImport = () => {
  const size = useWindowSize();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pin, setPin] = useState("");
  const [pinEntered, setPinEntered] = useState(false);
  const [words, setWords] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emptyError, setEmptyError] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [seedError, setSeedError] = useState(false);
  const urlParams = useParams();

  const handleSubmit = async () => {
    if (password === confirmPassword && password.length > 7) {
      setLoading(true);
      const wallet = "wallet_01";
      // TODO: Second argument is the extension encryption password, third argument is seed password
      try {
        const { serializedEncryptedMessage }: SaveMnemonicSeed =
          await backend.save_mnemonic_seed(words.trim(), password, "");

        window.localStorage.setItem("descriptor", serializedEncryptedMessage);

        const vault: GetEncryptedWallet = await backend.get_encrypted_wallet(
          password,
          serializedEncryptedMessage
        );
        const reloadScriptAndTabs = () => {
          if (isExtension) {
            chrome.runtime.sendMessage({
              call: "reload_script_and_tabs",
            });
          }
        };
        ReactGA.event({
          category: "Importing wallet",
          action: "Importing wallet",
        });
        Mixpanel.identify(vault.xpubkh);
        Mixpanel.track("Imported Wallet", { "Wallet ID": vault.xpubkh });
        let refresh = "";
        let token = "";
        const tokens = await backend.ln_auth(vault.xpubkh, vault.xprvkh);
        const handleWalletCreation = async (rf, tkn) => {
          await lib.storageSet({
            vault,
            lnCredentials: {
              login: vault.xpubkh,
              password: vault.xprvkh,
              refreshToken: rf,
              accessToken: tkn,
            },
          });
          reloadScriptAndTabs();
          navigate("/wallet", {
            state: {
              wallet,
              vault,
              lnCredentials: {
                login: vault.xpubkh,
                password: vault.xprvkh,
                refreshToken: rf,
                accessToken: tkn,
              },
            },
          });
        };
        if ("error" in tokens) {
          if (tokens.error === "UserDoesNotExist") {
            const lnWallet = await backend.ln_create_wallet(
              vault.xpubkh,
              vault.xprvkh
            );
            const retryTokens = await backend.ln_auth(
              vault.xpubkh,
              vault.xprvkh
            );
            if ("error" in retryTokens) {
              setError({
                title: "Error Ocurred on Wallet Import",
                message: retryTokens.error,
              });
              setOpen(true);
            } else {
              refresh = retryTokens.refresh;
              token = retryTokens.token;
              handleWalletCreation(refresh, token);
            }
          } else {
            setError({
              title: "Error Ocurred on Wallet Import",
              message: tokens.error,
            });
            setOpen(true);
          }
        } else {
          refresh = tokens.refresh;
          token = tokens.token;
          handleWalletCreation(refresh, token);
        }
      } catch (resError) {
        setLoading(false);
        if (resError instanceof Error) {
          setError({
            title: resError.name,
            message: resError.message,
          });
          setOpen(true);
        }
        console.error("error in WalletImport", resError);
      }
    } else {
      setOpen(true);
    }
  };

  const openWallet = async (seed: string, pubKeyHash: string) => {
    setLoading(true);
    const wallet = "wallet_01";
    // TODO: Second argument is the extension encryption password, third argument is seed password
    try {
      const { serializedEncryptedMessage }: SaveMnemonicSeed =
        await backend.save_mnemonic_seed(seed.trim(), pubKeyHash, pin.trim());

      window.localStorage.setItem("descriptor", serializedEncryptedMessage);

      const vault: GetEncryptedWallet = await backend.get_encrypted_wallet(
        pubKeyHash,
        serializedEncryptedMessage
      );
      const assetsVault: GetAssetsVault = await backend.get_assets_vault(
        vault.rgbAssetsDescriptorXpub,
        vault.rgbUdasDescriptorXpub
      );
      const handleOpenWallet = async () => {
        const reloadScriptAndTabs = () => {
          if (isExtension) {
            chrome.runtime.sendMessage({
              call: "reload_script_and_tabs",
            });
          }
        };
        console.debug(`Value is set to ${vault}`);
        ReactGA.event({
          category: "Importing wallet",
          action: "Importing wallet",
        });
        Mixpanel.identify(vault.xpubkh);
        Mixpanel.track("Imported Wallet", { "Wallet ID": vault.xpubkh });
        let refresh = "";
        let token = "";
        const tokens = await backend.ln_auth(vault.xpubkh, vault.xprvkh);
        const handleWalletCreation = async (rf, tkn) => {
          await lib.storageSet({
            vault,
            lnCredentials: {
              login: vault.xpubkh,
              password: vault.xprvkh,
              refreshToken: rf,
              accessToken: tkn,
            },
          });
          reloadScriptAndTabs();
          navigate("/wallet", {
            state: {
              wallet,
              vault,
              lnCredentials: {
                login: vault.xpubkh,
                password: vault.xprvkh,
                refreshToken: rf,
                accessToken: tkn,
              },
            },
          });
        };
        if ("error" in tokens) {
          if (tokens.error === "UserDoesNotExist") {
            const lnWallet = await backend.ln_create_wallet(
              vault.xpubkh,
              vault.xprvkh
            );
            const retryTokens = await backend.ln_auth(
              vault.xpubkh,
              vault.xprvkh
            );
            if ("error" in retryTokens) {
              setError({
                title: "Error Ocurred on Wallet Import",
                message: retryTokens.error,
              });
              setOpen(true);
            } else {
              refresh = retryTokens.refresh;
              token = retryTokens.token;
              handleWalletCreation(refresh, token);
            }
          } else {
            setError({
              title: "Error Ocurred on Wallet Import",
              message: tokens.error,
            });
            setOpen(true);
          }
        } else {
          refresh = tokens.refresh;
          token = tokens.token;
          handleWalletCreation(refresh, token);
        }
      };
      if (assetsVault.assets_output) {
        await handleOpenWallet();
      } else if (emptyError) {
        setLoading(false);
        await handleOpenWallet();
      } else {
        setLoading(false);
        setEmptyError(true);
      }
    } catch (err) {
      console.error("error in WalletImport", err);
    }
  };

  const handleSeedSubmit = async () => {
    if (urlParams.preload) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID || "", {
        debug: false,
        gaAddress: "analytics.js",
        gaOptions: {
          cookieDomain: "none",
        },
      });
      ReactGA.ga("set", "checkProtocolTask");
      ReactGA.pageview("WalletImportQR");
      const { seed, context, xpubkh }: Preload = JSON.parse(
        atob(urlParams.preload)
      );
      Mixpanel.track("Imported Paper Wallet", {
        Context: context,
      });
      openWallet(seed, xpubkh);
    } else {
      ReactGA.pageview("WalletImport");
      Mixpanel.track("Viewed Import Wallet");
    }
  };

  return urlParams.preload ? (
    <div className="flex flex-col justify-center h-full overflow-x-hidden overflow-y-auto">
      <div className="flex flex-col justify-between w-auto h-auto m-auto mx-0 text-center xs:pt-8 xs:pb-10 xs:mx-6 sm:mx-auto xs:bggradientlanding  sm:w-136 sm:shadow-xl rounded-xl">
        {!emptyError ? (
          <div className="h-auto px-6 text-center">
            <h1 className="px-3 mt-3 text-xl font-semibold text-center text-black xs:text-2xl dark:text-white">
              Add a seed password to the wallet
            </h1>
            <div className="flex justify-center w-full h-auto mt-3 mb-6">
              <PinPad
                pin={pin}
                setPin={setPin}
                onPinEntered={(val) => setPinEntered(val)}
              />
            </div>
            <RoundedButton
              className="w-10/12 py-3 mx-auto mt-4 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl disabled:pointer-events-none disabled:bg-opacity-25 disabled:select-none"
              onClick={() => handleSeedSubmit()}
              disabled={!pinEntered}
              loading={loading}
            >
              Import Wallet
            </RoundedButton>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-3 bg-red-100 rounded-full dark:bg-newdarkmode-700">
              <ExclamationTriangleIcon
                className="w-8 h-8 text-red-600 dark:text-yellow-500"
                aria-hidden="true"
              />
            </div>
            <h1 className="text-xl font-semibold text-center text-black xs:text-2xl dark:text-white">
              Error Importing Paper Wallet
            </h1>
            <p className="w-11/12 mx-auto mt-6 text-base font-light text-black dark:text-gray-300 sm:w-10/12">
              This wallet appears empty, did you enter the correct PIN?
            </p>
            <div className="w-11/12 mx-auto sm:w-9/12 pt-9 grid grid-cols-2">
              <RoundedButton
                className="mr-2 text-base text-black border-2 border-black-500 dark:border-yellow-500 dark:text-yellow-400"
                onClick={() => {
                  setEmptyError(false);
                  setPin("");
                }}
              >
                Cancel
              </RoundedButton>
              <RoundedButton
                className="ml-2 text-base bg-yellow-500 border-2 border-yellow-500 text-newdarkmode-900"
                onClick={() => {
                  if (urlParams.preload) {
                    const { seed, context, xpubkh }: Preload = JSON.parse(
                      atob(urlParams.preload)
                    );
                    openWallet(seed, xpubkh);
                  }
                }}
                loading={loading}
              >
                Create Anyway
              </RoundedButton>
            </div>
          </>
        )}
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </div>
  ) : (
    <div className="flex flex-col justify-center h-full overflow-x-hidden overflow-y-auto">
      <div className="flex flex-col justify-between w-auto h-auto m-auto mx-0 text-center xs:pt-8 xs:pb-10 xs:mx-6 sm:mx-auto xs:bggradientlanding  sm:w-136 sm:shadow-xl rounded-xl">
        <div className="h-auto px-6 text-center">
          <h1 className="text-3xl font-semibold text-center text-black sm:text-4xl dark:text-white">
            Import Wallet
          </h1>
          <TextField
            className="w-10/12 p-3 my-2"
            placeholder="Create New Password"
            type="password"
            autoComplete="new-password"
            onChange={(e) => setPassword(e.target.value)}
            darkest={size.width >= 500}
            data-test-id={TEST_IDS.walletimport.create}
          />
          {password.length < 8 && password !== "" && (
            <div className="w-10/12 m-auto">
              <p className="text-sm text-left text-red-600 flex-grow-default">
                Must be 8 characters
              </p>
            </div>
          )}
          <TextField
            className="w-10/12 p-3 my-2"
            placeholder="Confirm Password"
            type="password"
            autoComplete="new-password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            darkest={size.width >= 500}
            data-test-id={TEST_IDS.walletimport.confirm}
          />
          {password !== confirmPassword && confirmPassword !== "" && (
            <div className="w-10/12 m-auto">
              <p className="text-sm text-left text-red-600 flex-grow-default">
                Passwords must match
              </p>
            </div>
          )}
          <TextField
            className="w-10/12 p-3 my-2"
            placeholder="Enter Seed Phrase"
            value={words}
            onChange={(e) => setWords(e.target.value)}
            onKeyDown={(e) => (e.key === "Enter" ? handleSubmit() : null)}
            darkest={size.width >= 500}
            data-test-id={TEST_IDS.walletimport.mnemonic}
          />
          {!urlParams.seed && (
            <h3 className="w-10/12 mx-auto mb-6 text-base font-light text-left text-gray-700 dark:text-gray-300">
              Enter seed phrase in order from start to finish, with a single
              space after each word.
            </h3>
          )}
          {seedError && (
            <span className="text-base text-red-600">
              Incorrect Seed Phrase. Please try again.
            </span>
          )}
          <RoundedButton
            className="w-10/12 py-3 mx-auto mt-4 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl"
            onClick={() => handleSubmit()}
            loading={loading}
          >
            Import Wallet
          </RoundedButton>
        </div>
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </div>
  );
};

export default WalletImport;

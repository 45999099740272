import React, { useState } from "react";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import Circles from "src/Components/Steps/Circles";
import TextArea from "src/Components/Inputs/TextArea";
import useWindowSize from "src/Hooks/useWindowSize";
import { classNames } from "src/Hooks/util";

const Card2 = ({ handleChange, ...props }) => {
  const size = useWindowSize();
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);
  const phrases = props.mnemonic;

  const sameMembers = (arr1, arr2) => {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    return (
      arr1.every((item) => set2.has(item)) &&
      arr2.every((item) => set1.has(item))
    );
  };

  const handleSubmit = () => {
    if (sameMembers(inputValue.trim().toLowerCase().split(" "), phrases)) {
      setError(false);
      props.setSteps([
        { name: "Set Password", href: "#", status: "complete" },
        { name: "Secure Wallet", href: "#", status: "complete" },
        {
          name: "Confirm Seed Phrase",
          href: "#",
          status: "current",
        },
      ]);
      handleChange("complete");
    } else {
      setError(true);
    }
  };

  return (
    <div className="w-auto h-auto xs:py-6 sm:py-12 m-auto mx-1.5 xs:mx-6 text-center text-gray-900 sm:w-136 xs:shadow-xl rounded-xl xs:bggradientlanding dark:text-gray-300">
      <Circles steps={props.steps} />
      <h1 className="w-11/12 m-auto my-4 text-2xl font-semibold leading-tight text-center xs:text-3xl  xs:w-10/12 flex-grow-default">
        Secret backup phrase confirmation
      </h1>
      <p className="w-11/12 px-1.5 m-auto my-2 text-base xs:text-lg font-light text-left text-black xs:w-9/12 dark:text-white flex-grow-default leading-8">
        Enter your secret seed phrase in the text box below. Write out each word
        with one space in between all in lower case. Example: kitten trees couch
        …
      </p>
      <div className="w-11/12 m-auto xs:w-9/12 grid grid-cols-3 py-1.5">
        {phrases.map((phrase, i) => (
          <div
            key={phrase}
            className={classNames(
              phrase === inputValue.trim().toLowerCase().split(" ")[i]
                ? "bg-green-500"
                : "text-black dark:text-gray-400 ",
              inputValue.trim().toLowerCase().split(" ")[i] &&
                !inputValue.trim().toLowerCase().split(" ")[i + 1] &&
                phrase !== inputValue.trim().toLowerCase().split(" ")[i]
                ? "bg-orange-500 text-black dark:text-black"
                : "",
              inputValue.trim().toLowerCase().split(" ")[i] &&
                inputValue.trim().toLowerCase().split(" ")[i + 1] &&
                phrase !== inputValue.trim().toLowerCase().split(" ")[i]
                ? "bg-red-500 text-black dark:text-black"
                : "",
              !inputValue.trim().toLowerCase().split(" ")[i]
                ? "border-1 border-gray-400 dark:border-newdarkmode-600 border-opacity-50"
                : "",
              "text-base rounded-lg p-1 xs:p-1.5 m-1.5 text-black text-left"
            )}
          >
            {i + 1}. {phrase}
          </div>
        ))}
      </div>
      <TextArea
        className="w-11/12 p-2 xs:w-9/12"
        rows={2}
        placeholder="Enter seed phrase here."
        onChange={(e) => {
          setError(false);
          setInputValue(e.target.value);
        }}
        onKeyDown={(e) => (e.key === "Enter" ? handleSubmit() : null)}
        darkest={size.width >= 500}
      />
      {error && (
        <h3 className="px-6 text-base text-gray-400 sm:pb-3">
          Incorrect Secret Phrase. Please try again.
        </h3>
      )}
      <RoundedButton
        className="w-9/12 py-3 mx-auto my-6 text-base font-semibold text-gray-900 bg-yellow-500 sm:text-lg lg:text-xl"
        onClick={() => handleSubmit()}
      >
        Confirm
      </RoundedButton>
    </div>
  );
};

export default Card2;

/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* global chrome */
import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import ReactGA from "react-ga";
import { TEST_IDS } from "tests/ids";

import Loader from "src/Components/Loaders/Loader";
import { MainNavContext } from "src/Hooks/MainNavContext";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import SecondaryNav from "src/Components/Navbar/SecondaryNav";
import ArrowDownCircle from "src/Components/Icons/ArrowDownCircle";
import Footer from "src/Components/Layout/Footers/Footer";
import lib, { isExtension } from "src/lib";
import useWindowSize from "src/Hooks/useWindowSize";
import { Vault, WalletData, LnCredentials } from "src/types";
import Mixpanel from "src/lib/Mixpanel";
import { classNames } from "src/Hooks/util";
// import Image from 'next/image';
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { v4 as uuidv4 } from "uuid";
import CustomSwiper from "src/Components/Swiper/CustomSwiper";

const Welcome = () => {
  const size = useWindowSize();
  const [height, setHeight] = useState(0);
  const { setMainNav } = useContext(MainNavContext);
  const navigate = useNavigate();
  const [view, setView] = useState(true);
  const [isVaultLoading, setIsVaultLoading] = useState(true);
  const featuresRef = useRef(null);
  const web3Ref = useRef(null);
  const blogRef = useRef(null);
  const [, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");

  const onSubmit = () => {
    if (email !== "") {
      const re = /\S+@\S+\.\S+/;
      if (re.test(email)) {
        setError(false);
        // TODO: send email
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };
  const CARD_OFFSET = 40;
  const SCALE_FACTOR = 0.06;

  const blogs = [
    {
      title: "Who is building Diba?",
      description: "Learn about the people buildng the DIBA products.",
      img: "images/whoisbuildingdiba.png",
      link: "/blog/WhoIsBuildingDiba",
      id: uuidv4(),
    },
    {
      title: "Why This Design? The Story behind DIBA’s Merch.",
      description:
        "Merch without concept is just merch. It’s a sticker or shirt in the company’s color palette with elements like a logo, website or a bit of text.",
      img: "images/merch1.webp",
      link: "/blog/WhyThisDesign",
      id: uuidv4(),
    },
    {
      title: "NFT NYC 2022 showcases Bitcoin Art with DIBA",
      description:
        "Art and Assets built on Bitcoin make their existence known into the mainstream NFT scene. DIBA presents on the NFT NYC Conference panel and attends 50+ surrounding events.",
      img: "images/NFTNYC2022.jpeg",
      link: "/blog/NFTNYC2022",
      id: uuidv4(),
    },
    {
      title: "DIBA Presents at 2022 Bitcoin Conference",
      description:
        "This year, DIBA had a presence at the largest, most reputable Bitcoin event in the world through TALKS, DIGITAL ART, and DIBA’s first ever official AFTERPARTY.",
      img: "images/DibaPresents2022BTCCon.webp",
      link: "/blog/2022BTCCon",
      id: uuidv4(),
    },
    {
      title: "BitMask on Chrome Store",
      description:
        "DIBA Global is proud to share the latest BitMask, the wallet for utility directly on bitcoin. Version 0.1.1.0 is available to download as a Google Chrome extension.",
      img: "images/BitmaskonChromeStore.webp",
      link: "/blog/BitmaskonChrome",
      id: uuidv4(),
    },
    {
      title:
        "Diba is a Top 10 Finalist at Bitcoin Conference 2021 Pitch Competition",
      description:
        "Out of hundreds of submissions, Diba was selected to present their project as a Top 10 Finalist at the 2021 Bitcoin Conference in Miami on Whale Day.",
      img: "images/DibaIsTopTen.webp",
      link: "/blog/DibaIsTop10",
      id: uuidv4(),
    },
  ];

  const spring = {
    type: "spring",
    damping: 10,
    stiffness: 80,
  };

  const handleRedirectToFullscreenTab = () => {
    window.close();
    const win = window.open(`${window.location.href.split("?")[0]}`, "_blank");
    win?.focus();
  };

  const getWalletMode = () =>
    window.location.href.includes("chrome-extension://")
      ? "Extension"
      : "Web Wallet";

  function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        if (savedCallback.current) savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    setView(!view);
  }, 5000);

  useLayoutEffect(() => {
    setHeight(size.height - 80);
  }, [size]);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID || "", {
      debug: false,
      gaAddress: "analytics.js",
      gaOptions: {
        cookieDomain: "none",
      },
    });
    ReactGA.ga("set", "checkProtocolTask");
    ReactGA.pageview("Welcome");
    (async () => {
      const isPopup = window.location.href.split("?").pop() === "popup";
      Mixpanel.register_once({
        "Wallet Mode": getWalletMode(),
      });
      setIsVaultLoading(true);

      if (localStorage.getItem("descriptor")) {
        const result = await lib.storageGet<WalletData>(["wallet"]);

        if (result.wallet) {
          if (setMainNav) setMainNav(true);

          const { vault } = await lib.storageGet<Vault>(["vault"]);
          const { lnCredentials } = await lib.storageGet<LnCredentials>([
            "lnCredentials",
          ]);

          if (vault) {
            ReactGA.event({
              category: "Welcome",
              action: "wallet",
            });
            navigate("/wallet", {
              replace: true,
              state: { wallet: result.wallet.name, vault, lnCredentials },
            });
          } else {
            ReactGA.event({
              category: "Welcome",
              action: "sign in",
            });
            navigate("/signin", { replace: true });
          }
        } else if (
          isPopup &&
          window.location.href.split("/").pop()?.startsWith("index.html")
        ) {
          setIsVaultLoading(false);
          handleRedirectToFullscreenTab();
        } else {
          setIsVaultLoading(false);
        }
      } else if (
        isPopup &&
        window.location.href.split("/").pop()?.startsWith("index.html")
      ) {
        setIsVaultLoading(false);
        handleRedirectToFullscreenTab();
      } else {
        setIsVaultLoading(false);
        Mixpanel.track("Viewed Landing Page");
      }
    })();
  }, []);

  if (isVaultLoading) {
    return (
      <div
        className={classNames(
          isExtension ? "min-w-[400px]" : "min-w-80",
          "flex justify-center w-full h-screen m-auto text-gray-900 dark:text-gray-300 bg-gray-100 dark:bggradientlanding"
        )}
      >
        <Loader className="w-20 h-20 m-auto" />
      </div>
    );
  }

  return (
    <div className="sticky top-0 z-50 flex flex-col justify-center h-full min-h-full overflow-hidden font-jetbrains bggradientlanding">
      <SecondaryNav
        menuItems={[
          { name: "Deep Web3", ref: web3Ref },
          { name: "Features", ref: featuresRef },
          { name: "Blog", ref: blogRef },
          { name: "FAQ", route: "faq" },
          { name: "GitHub", link: "https://github.com/diba-io/bitmask-core/" },
        ]}
      />
      <div
        style={{
          height,
          minHeight: height,
        }}
        className="w-full my-auto overflow-y-auto dark:darkscrollbar"
      >
        <div className="flex flex-col justify-center h-auto">
          <div className="h-full min-h-full">
            <div className="flex flex-col justify-center h-auto my-auto">
              <section className="relative h-auto min-w-full pt-6 my-auto overflow-hidden min-h-page grid grid-cols-12 bggradientlanding">
                <div className="flex flex-col w-10/12 h-auto max-w-2xl lg:max-w-[43rem] mx-auto text-left text-black md:w-full col-span-12 xl:col-span-7 3xl:col-span-8 2xl:max-w-4xl 3xl:max-w-5xl lg:my-auto dark:text-white 5xl:mr-0 5xl:ml-auto xl:pl-6">
                  <div className="relative z-20 w-full m-auto text-left text-black whitespace-pre-wrap dark:text-white xs:mx-3 xl:mx-0">
                    <h1 className="text-2xl xs:text-3xl md:text-4xl 2xl:text-5xl lg:block">
                      BitMask Wallet: Your Gateway to DeepWeb3 on Bitcoin
                    </h1>{" "}
                    <p className="text-base font-light tracking-normal xs:text-lg leading-7 sm:leading-8 lg:leading-9 sm:text-xl lg:p-0 md:tracking-wide">
                      The Browser Extension for Decentralized Applications on
                      Bitcoin. Access Bitcoin Finance, NFTs (UDAs) and more
                      using RGB protocol, with full financial sovereignty
                      enabled through our taproot-enabled Bitcoin and Lightning
                      Network wallet.
                    </p>{" "}
                  </div>
                  <div className="flex justify-center w-full pb-1.5 sm:pb-4">
                    <div className="w-full my-3 text-gray-900 grid grid-cols-12 md:flex-row sm:mt-6 lg:mt-9 gap-6 2xl:gap-10 xs:mx-3 xl:mx-0">
                      {isExtension ? (
                        <>
                          <RoundedButton
                            style={{ background: "#272F3F" }}
                            className="z-20 w-auto bg-gray-100 h-14 xs:h-16 rounded-4xl col-span-12 sm:col-span-6"
                            onClick={() => navigate("/newuser")}
                          >
                            <p className="m-auto text-lg sm:text-[1.05rem] 2xl:text-xl px-3">
                              Continue with Extension
                            </p>
                          </RoundedButton>
                          <RoundedButton
                            className="z-20 w-auto bg-gray-100 h-14 xs:h-16 rounded-4xl col-span-12 sm:col-span-6"
                            onClick={() =>
                              window.open("https://bitmask.app", "_blank")
                            }
                          >
                            <p className="mx-auto my-auto text-lg lg:text-xl">
                              Go to Web Wallet
                            </p>
                          </RoundedButton>
                        </>
                      ) : (
                        <>
                          <RoundedButton
                            style={{ background: "#272F3F" }}
                            className="z-20 w-auto bg-gray-100 h-14 xs:h-16 rounded-4xl col-span-12 sm:col-span-6"
                            onClick={() =>
                              window.open(
                                "https://chrome.google.com/webstore/detail/bitmask/momakdpclmaphlamgjcndbgfckjfpemp",
                                "_blank"
                              )
                            }
                          >
                            <img
                              className="w-auto h-auto mx-auto my-auto max-h-11 xs:max-h-12"
                              src="images/extensionDownload.png"
                              alt="Google chrome extension download"
                            />
                          </RoundedButton>
                          <RoundedButton
                            className="z-20 w-auto bg-gray-100 h-14 xs:h-16 rounded-4xl col-span-12 sm:col-span-6"
                            onClick={() => navigate("/newuser")}
                          >
                            <p className="m-auto text-lg sm:text-[1.05rem] 2xl:text-xl px-3">
                              Continue with Web Wallet
                            </p>
                          </RoundedButton>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="hidden xl:flex lg:order-2 z-30 w-full h-auto col-span-5 3xl:col-span-4 max-h-[70vh] my-auto justify-end">
                  <img
                    className="object-contain object-right w-full h-auto mr-0"
                    src="https://s3.diba.io/bitmaskMultiWalletLeft.webp"
                    alt="macbook with bitmask extension open"
                  />
                </div>
                <div className="relative z-50 flex w-auto h-auto max-w-full mx-auto xl:hidden lg:order-2 -mb-36 col-span-12">
                  <img
                    className="h-auto max-h-[50vh] w-full object-contain"
                    src="https://s3.diba.io/macbookMockup.webp"
                    alt="macbook with bitmask extension open"
                  />
                </div>
                <div className="absolute bottom-0 left-0 right-0 flex m-auto opacity-50 md:hidden top-64 saturate-200">
                  <img
                    className="xs:w-auto xs:h-full"
                    src="https://s3.diba.io/LineWaveMedium.webp"
                    alt="line waves in the background of the page"
                  />
                </div>
                <div className="absolute top-0 bottom-0 left-0 right-0 hidden m-auto opacity-50 md:flex saturate-200">
                  <img
                    className="min-w-full xs:w-auto xs:h-full"
                    src="https://s3.diba.io/LineWave.webp"
                    alt="line waves in the background of the page"
                  />
                </div>
                <div className="absolute bottom-0 right-0 order-2 hidden lg:flex opacity-[65%]">
                  <img
                    className="w-auto h-auto"
                    src="images/Layer31.png"
                    alt="rounded shape with purple gradient"
                  />
                </div>
              </section>
              <section className="relative hidden h-auto my-auto overflow-hidden lg:pt-0 bggradientlanding">
                <div className="flex flex-row order-1 w-10/12 h-auto m-auto text-black col-span-6 dark:text-white 5xl:mr-0 5xl:ml-auto 4xl:col-span-5 py-9">
                  <div className="relative z-20 flex flex-col justify-center w-full m-auto text-black dark:text-white">
                    <h1 className="mr-6 text-xs text-left md:text-sm lg:text-base md:text-left">
                      Unlock product potential and insider tips. Sign up for our
                      exclusive newsletter.
                    </h1>
                  </div>
                  <div className="flex flex-row w-full xs:mx-auto 2xl:flex-row">
                    <input
                      id="link"
                      name="link"
                      className="flex-auto w-auto h-12 py-3 pl-2 pr-3 my-4 mr-auto text-base font-light placeholder-gray-500 bg-gray-100 rounded-lg min-w-52 xs:max-w-full border-darkmode-100 sm:mr-4 xs:min-w-64 2xl:w-auto lg:max-w-96 dark:placeholder-darkmode-100 dark:bg-newdarkmode-700 dark:text-gray-300 dark:border-darkmode-100 border-1/2 focus:outline-none focus:text-gray-900 dark:focus:text-gray-300 focus:placeholder-gray-500 focus:ring-1 focus:ring-green-400 focus:border-green-400 sm:text-sm"
                      placeholder="Enter Email"
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value.trim());
                        setSuccess(false);
                      }}
                      onKeyDown={(e) => (e.key === "Enter" ? onSubmit() : null)}
                    />
                    {email !== "" && /\S+@\S+\.\S+/.test(email) === false && (
                      <p className="mb-4 text-sm font-thin tracking-wide text-gray-500 md:text-base xl:hidden">
                        Please enter a valid email address.
                      </p>
                    )}
                    <RoundedButton
                      onClick={() => onSubmit()}
                      className="flex w-auto h-12 mx-auto my-auto ml-3 font-bold text-black bg-yellow-500 rounded-lg md:px-14"
                    >
                      Subscribe
                    </RoundedButton>
                  </div>
                  {email !== "" && /\S+@\S+\.\S+/.test(email) === false && (
                    <p className="hidden my-auto ml-8 text-sm font-thin tracking-wide text-gray-500 md:text-base xl:block">
                      Please enter a valid email address
                    </p>
                  )}
                  {success && (
                    <p className="my-auto ml-8 text-sm font-thin tracking-wide text-gray-500 md:text-base">
                      Thank you! We will keep you in the loop.
                    </p>
                  )}
                </div>
              </section>
              <section
                ref={web3Ref}
                id="web3"
                className="relative h-auto pt-6 my-auto overflow-hidden sm:pt-8 lg:pt-0 bggradientlanding"
              >
                <div className="mx-6 my-9 md:my-20 sm:mx-12">
                  <div className="flex flex-col order-2 w-full h-auto m-auto text-center text-black col-span-6 md:w-10/12 dark:text-white 5xl:mr-0 5xl:ml-auto 4xl:col-span-5">
                    <div className="relative z-20 flex flex-col justify-center w-full m-auto text-black dark:text-white">
                      <h2 className="text-2xl md:text-3xl xl:text-4xl">
                        Welcome to the Deep Web3
                      </h2>
                      <div className="mt-6 text-lg mb-9">
                        <p> One that's controlled by you.</p>
                      </div>
                    </div>
                    <div className="z-20 flex flex-wrap items-center justify-center mx-auto gap-x-12 md:gap-x-20 md:gap-y-12 gap-y-6">
                      <a
                        href="https://bitcoin.org/en/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div
                          style={{ background: "#151C2D" }}
                          className="relative hover:scale-[105%] duration-300 z-20 w-auto h-auto max-w-74 sm:max-w-88 rounded-xl py-3 xs:py-6 px-1.5 xs:px-3"
                        >
                          <ArrowDownCircle className="w-12 h-12 mx-auto my-auto mb-3" />
                          <p className="mx-2 mb-3 text-lg text-center">
                            Bitcoin Network{" "}
                          </p>
                          <p className="mx-3 text-base font-light text-center">
                            A trustless and decentralized settlement layer,
                            public ledger and timechain.
                          </p>
                        </div>
                      </a>
                      <a
                        href="https://lightning.network/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div
                          style={{ background: "#151C2D" }}
                          className="relative hover:scale-[105%] duration-300 z-20 w-auto h-auto max-w-74 sm:max-w-88 rounded-xl py-3 xs:py-6 px-1.5 xs:px-3"
                        >
                          <ArrowDownCircle className="w-12 h-12 mx-auto my-auto mb-3" />
                          <p className="mx-2 mb-3 text-lg text-center">
                            Lightning Network{" "}
                          </p>
                          <p className="mx-3 text-base font-light text-center">
                            The payment layer with very low fees and 40 million
                            transactions per second.
                          </p>
                        </div>
                      </a>
                      <a
                        href="https://rgb.info/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div
                          style={{ background: "#151C2D" }}
                          className="relative hover:scale-[105%] duration-300 z-20 w-auto h-auto max-w-74 sm:max-w-88 rounded-xl py-3 xs:py-6 px-1.5 xs:px-3"
                        >
                          <ArrowDownCircle className="w-12 h-12 mx-auto my-auto mb-3" />
                          <p className="mx-2 mb-3 text-lg text-center">
                            RGB Smart-Contract{" "}
                          </p>
                          <p className="mx-3 text-base font-light text-center">
                            A smart contract system that is private, scalable,
                            and client-side validated.
                          </p>
                        </div>
                      </a>
                      <a
                        href="https://github.com/diba-io/carbonado"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div
                          style={{ background: "#151C2D" }}
                          className="relative hover:scale-[105%] duration-300 z-20 w-auto h-auto max-w-74 sm:max-w-88 rounded-xl py-3 xs:py-6 px-1.5 xs:px-3"
                        >
                          <ArrowDownCircle className="w-12 h-12 mx-auto my-auto mb-3" />
                          <p className="mx-2 mb-3 text-lg text-center">
                            Carbonado{" "}
                          </p>
                          <p className="mx-3 text-base font-light text-center">
                            An apocalypse-resistant data storage format for the
                            truly paranoid.
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="relative bottom-0 left-0 z-0 order-2 w-full h-full">
                  <img
                    className="absolute bottom-0 left-0 z-0"
                    src="images/largeBubble.png"
                    alt="3D floating bubble"
                  />
                </div>
                <div className="absolute z-0 w-full h-full top-4 right-4 xs:top-20 xs:right-20">
                  <img
                    className="absolute top-0 right-0 z-0"
                    src="images/smallBubble.png"
                    alt="3D floating bubble"
                  />
                </div>
              </section>
              <section
                className="relative h-auto pt-6 my-auto overflow-hidden min-h-[60rem] sm:pt-8 lg:pt-0"
                style={{
                  backgroundImage:
                    "url(https://s3.diba.io/perspectiveWallets.webp)",
                  backgroundSize: "cover",
                }}
              >
                <div className="absolute bottom-16 sm:bottom-30 lg:bottom-40 left-9 sm:left-16 lg:left-40 w-auto h-auto bg-newdarkmode-700 bg-opacity-[85%] max-w-80 sm:max-w-xl lg:max-w-2xl 2xl:max-w-3xl rounded-xl">
                  <p className="text-lg font-light text-white md:text-xl lg:text-2xl xl:text-[1.7rem] p-6 sm:p-12">
                    Develop products that prioritize user privacy with BitMask's
                    open-source core functionality - create solutions that
                    empower users to control their own data.{" "}
                  </p>
                </div>
              </section>
              <section
                ref={featuresRef}
                className="relative flex flex-row justify-center w-full h-auto m-auto overflow-hidden bg-newdarkmode-900"
              >
                <div className="flex justify-center w-11/12 h-full my-auto text-right text-black xl:w-full dark:text-white">
                  <div className="relative z-20 flex flex-col flex-wrap justify-center w-full p-6 m-auto text-black xl:pl-20 md:p-12 dark:text-white">
                    <h3 className="text-2xl text-center md:text-3xl xl:text-4xl">
                      DIBA powered by BitMask{" "}
                    </h3>
                    {[
                      "Collect and trade your UDAs (Bitcoin NFTs) that are bound to Bitcoin’s UTXO.",
                      "Create UDAs (Unique Digital Assets) for free, instantly. No cost or delay.",
                      "Sleep soundly knowing that BitMask's multiple encryption layers guarantee the safety of your assets and eradicate the risk of rug pulls.",
                    ].map((point) => (
                      <ul className="flex flex-row justify-center pt-6 mx-auto my-auto ml-0 text-left sm:pt-9">
                        <li className="m-auto text-lg font-light list-disc md:text-lg">
                          {point}
                        </li>
                      </ul>
                    ))}
                    <div className="flex flex-row h-full mx-auto text-left pt-9 xs:pt-12">
                      <a
                        href="https://diba.io/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="z-20 w-auto min-h-full px-6 py-2 mx-auto text-xl bg-yellow-500 rounded-lg text-newdarkmode-900">
                          Check it out
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex-wrap hidden object-cover w-full h-full xl:flex">
                  <img
                    className="z-0 object-cover w-full h-auto min-h-full"
                    src="https://s3.diba.io/dibaAppleMockup.webp"
                    alt="MacBook with DIBA and BitMask extension open"
                  />
                </div>
              </section>
              <section
                className="px-6 py-9 md:py-20 bggradientlanding"
                id="blog"
                ref={blogRef}
              >
                <CustomSwiper>
                  <div className="p-3 mx-6 sm:p-12">
                    {blogs.map((blog) => (
                      <SwiperSlide className="swiper-slide" key={blog.id}>
                        <div className="w-auto h-auto mb-12 shadow-xl xs:mt-6 md:mx-3 min-h-112 rounded-xl bg-newdarkmode-800">
                          <div className="w-auto overflow-hidden cursor-pointer rounded-xl">
                            <Link to={blog.link} key={blog.id}>
                              <div className="relative z-0 select-none h-72">
                                <img
                                  className="object-cover w-full h-full pointer-events-none select-none rounded-t-xl"
                                  src={blog.img}
                                  crossOrigin="anonymous"
                                  alt="Blog post thumbnail"
                                />
                              </div>
                              <div className="z-10 h-auto px-4 pt-3 pb-2 text-gray-200 select-none">
                                <h3 className="pb-2 text-base font-thin text-center text-white truncate md:text-lg font-integral">
                                  {blog.title}
                                </h3>
                                <p className="mx-3 my-auto overflow-hidden text-sm font-light text-center md:text-base overflow-ellipsis line-clamp-3 leading-7">
                                  {blog.description}
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </div>
                </CustomSwiper>
              </section>
            </div>
          </div>
          <div className="sm:pt-0">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;

/* eslint-disable import/prefer-default-export */
import React from "react";

const { CONTEXT = "" } = process.env;

export const releaseNotes =
  CONTEXT === "MIXD Future Factory"
    ? [
        <p className="text-lg dark:text-gray-400 sm:text-xl lg:text-2xl">
          Welcome to The Future Factory. The future of immersive music & art.
        </p>,
      ]
    : [
        <p>Audio UDAs minting compatibility with the DIBA marketplace</p>,
        <p>Playback of Audio UDAs within the wallet</p>,
        <p>
          Lightning support now added to the web wallet, in addition to the
          extension
        </p>,
        <p>Wallet UI fixes and improvements</p>,
      ];

export const termsOfService = [
  {
    title: "Acceptance of Service Terms",
    body: (
      <p className="text-base whitespace-pre-line sm:text-lg">
        The BitMask Testnet Wallet (“Testnet Wallet”) is a self-custody wallet
        provided for testing purposes (See Section 3 below), that provides a
        web-based platform for managing your accounts of Testnet Bitcoin or
        “tSats” and any Unique Digital Assets ”UDAs”/Tokens created on Testnet.
        The Testnet Wallet enables interaction with decentralized applications
        on the Bitcoin Testnet, while keeping you in control over what
        operations you approve. The Testnet Wallet is{" "}
        <a
          href="https://chrome.google.com/webstore/detail/bitmask/momakdpclmaphlamgjcndbgfckjfpemp"
          className="underline"
        >
          provided through the Chrome Web Store
        </a>{" "}
        as a browser plugin - which includes access to text, images, audio, code
        and other materials (collectively, the “Content”), and all of the
        features, and services provided. The browser plug-in and any other
        features, tools, materials, or other services offered from time to time
        by BitMask for the Testnet Wallet are referred to here as the “Wallet
        Services”. Please read these Terms carefully before using the Wallet
        Services. By using, or otherwise accessing the Wallet Services, or
        clicking to accept, or agree to these Terms where that option is made
        available, you (1) accept and agree to these BitMask Testnet Wallet
        Terms, and (2) consent to the collection, use, disclosure and other
        handling of information as described.
      </p>
    ),
  },
  {
    title: "Account Password and Security",
    body: (
      <p className="text-base whitespace-pre-line sm:text-lg">
        When setting up an account within the Testnet Wallet you are solely
        responsible for the retention and security of your private key and your
        twelve-word mnemonic phrase (“Secret Phrase”) associated with your
        wallet. You must keep your wallet address, Secret Phrase, and private
        key access information secure. It is very important that you backup your
        private keys, backup phrases or passwords. Failure to do so may result
        in the loss of control of Digital Assets associated with your Testnet
        Wallet. You acknowledge and agree that we do not receive or store your
        Testnet Wallet password, encrypted private key, unencrypted private key,
        or Secret Phrase associated with your Testnet Wallet. We cannot generate
        a new password for your Testnet wallet if you fail to remember your
        original password. If you have not safely stored a backup of any Testnet
        Wallet address and private key pairs maintained in your Testnet Wallet,
        you accept and acknowledge that any Digital Assets you have associated
        with such Testnet Wallet address will become inaccessible.
      </p>
    ),
  },
  {
    title: "Testing Purposes",
    body: (
      <p className="text-base whitespace-pre-line sm:text-lg">
        The Testnet Wallet and the Wallet Services and Content are only for
        testing purposes in a non-production environment on Bitcoin Testnet. The
        Testnet Wallet and Wallet Services are only for managing your accounts
        of tSats and Testnet UDAs created on Bitcoin Testnet. The Testnet Wallet
        and Wallet Services should not, nor are they intended to be used: (1) on
        Bitcoin blockchain other than the Testnet; and (2) to manage any
        accounts other than that of tSats and Testnet UDAs created on Bitcoin
        Testnet. Testnet Wallet is not intended to be relied on for any reason
        whatsoever or to be used in a production environment. You acknowledge
        and accept that the Testnet Wallet and the Wallet Services (a) may
        contain bugs, errors and defects; (b) may function improperly or be
        subject to periods of downtime and unavailability; (c) may result in
        total or partial loss or corruption of data; (d) may result in partial
        or total inability to access or loss of tSats or UDAs in the Testnet
        Wallet; and (e) may be modified at any time, including through the
        release of subsequent versions, all with or without notice to you. The
        Testnet Wallet and Wallet Services are available on an “as is” basis for
        the sole purpose of collecting feedback on quality, usability,
        performance and defects.
      </p>
    ),
  },
  {
    title: "Modification of Terms of Use",
    body: (
      <p className="text-base whitespace-pre-line sm:text-lg">
        BitMask by DIBA Global INC reserves the right, at its sole discretion,
        to modify or replace these Terms with every new version release. The
        most current version of these Terms will be posted on our website. You
        shall be responsible for reviewing and becoming familiar with any such
        modifications. Use of the Wallet Services by you after any modification
        to the Terms constitutes your acceptance of the BitMask Testnet Wallet
        Terms as modified.
      </p>
    ),
  },
  {
    title: "Support",
    body: (
      <p className="text-base whitespace-pre-line sm:text-lg">
        You may contact us through{" "}
        <a href="https://t.me/tryDIBA" className="underline">
          Telegram
        </a>{" "}
        or by emailing us at info@diba.io for assistance with issues that you
        have with use of Testnet Wallet, but we do not provide any service level
        commitments with respect to our customer service, troubleshooting, or
        updates relating to these Services.
      </p>
    ),
  },
  {
    title: "Testnet Cookies",
    body: (
      <p className="text-base whitespace-pre-line sm:text-lg">
        By accessing and using Testnet Wallet, you acknowledge that you have
        read and understood the Cookies Policy, which sets out how we use
        cookies in connection with your access and use of Testnet.{" "}
        <a href="https://t.me/tryDIBA" className="underline">
          The Cookies Policy is available here.
        </a>
      </p>
    ),
  },
];
